import React, { useEffect, useState } from "react";
import axios from "../../api/axiosConfig";
import Header from "../Header";
import Swal from "sweetalert2";
import Loader from "../loader";
import "../../index.css";

const CrearFacturaGeneral = () => {
  const [productos, setProductos] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [loading, setLoading] = useState(false);
  const sucursalIdUser = localStorage.getItem("branchId");
  const [facturasGenerales, setFacturasGenerales] = useState([]);

  const [factura, setFactura] = useState({
    Receptor: {
      RFC: "XAXX010101000",
      NombreRazonSocial: "Público en General",
      UsoCFDI: "",
      CodigoPostal: "",
      RegimenFiscal: "",
      Correo: "",
    },
    Conceptos: [
      {
        indexLista: "",
        Cantidad: "1",
        CodigoUnidad: "H87",
        Unidad: "PZ",
        CodigoProducto: "",
        Producto: "",
        PrecioUnitario: "",
        Importe: "",
        Descuento: "",
        DescuentoCalculado: "",
        TextoAdicional: "",
      },
    ],
    FormaPago: "",
    MetodoPago: "",
    SubTotal: "",
    Descuento: "",
    DescuentoCalculado: "",
    Total: "",
    CPLugarExpedicion: "",
    IdSucursal: localStorage.getItem("branchId"),
  });

  const usosCFDI = [
    { codigo: "G01", descripcion: "Adquisición de mercancías" },
    { codigo: "G02", descripcion: "Devoluciones, descuentos o bonificaciones" },
    { codigo: "G03", descripcion: "Gastos en general" },
    { codigo: "I01", descripcion: "Construcciones" },
    {
      codigo: "I02",
      descripcion: "Mobiliario y equipo de oficina por inversiones",
    },
    { codigo: "I03", descripcion: "Equipo de transporte" },
    { codigo: "I04", descripcion: "Equipo de cómputo y accesorios" },
    {
      codigo: "I05",
      descripcion: "Dados, troqueles, moldes, matrices y herramental",
    },
    { codigo: "I06", descripcion: "Comunicaciones telefónicas" },
    { codigo: "I07", descripcion: "Comunicaciones satelitales" },
    { codigo: "I08", descripcion: "Otra maquinaria y equipo" },
    {
      codigo: "D01",
      descripcion: "Honorarios médicos, dentales y gastos hospitalarios",
    },
    {
      codigo: "D02",
      descripcion: "Gastos médicos por incapacidad o discapacidad",
    },
    { codigo: "D03", descripcion: "Gastos funerales" },
    { codigo: "D04", descripcion: "Donativos" },
    {
      codigo: "D05",
      descripcion:
        "Intereses reales efectivamente pagados por créditos hipotecarios",
    },
    { codigo: "D06", descripcion: "Aportaciones voluntarias al SAR" },
    { codigo: "D07", descripcion: "Primas por seguros de gastos médicos" },
    {
      codigo: "D08",
      descripcion: "Gastos de transportación escolar obligatoria",
    },
    {
      codigo: "D09",
      descripcion:
        "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
    },
    {
      codigo: "D10",
      descripcion: "Pagos por servicios educativos (colegiaturas)",
    },
    { codigo: "P01", descripcion: "Por definir" },
    { codigo: "S01", descripcion: "Sin Efectos Fiscales" },
  ];

  const formasPago = [
    { codigo: "01", descripcion: "Efectivo" },
    { codigo: "03", descripcion: "Transferencia electrónica de fondos" },
    { codigo: "04", descripcion: "Tarjeta de crédito" },
    { codigo: "28", descripcion: "Tarjeta de débito" },
  ];

  const metodosPago = [
    { codigo: "PUE", descripcion: "Pago en Una sola Exhibición" },
    { codigo: "PPD", descripcion: "Pago en Parcialidades o Diferido" },
  ];

  useEffect(() => {
    axios
      .get("/facturacion/productos")
      .then((response) => {
        setProductos(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los productos:", error);
      });

    axios
      .get("/sucursales")
      .then((response) => {
        const sucursalesList = response.data.data;
        const sucursal = sucursalesList.filter(
          (sucursal) => sucursal.id == sucursalIdUser
        );
        setFactura((prevFactura) => ({
          ...prevFactura,
          CPLugarExpedicion: sucursal[0].codigoPostal,
        }));
      })
      .catch((error) => {
        console.error("Error al obtener los productos:", error);
      });
  }, []);

  const facturasGeneralesFunction = async (metodoPago) => {
    if (metodoPago === "") {
      return;
    }
    setLoading(true);
    try {
      const params = { metodoPago: metodoPago };
      const response = await axios.get("/facturacion/facturaPublicoGeneral", {
        params,
      });
      const facturas = response.data;
      if (facturas.success === false) {
        Swal.fire({
          icon: "info",
          title: `No hay transacciones con metodo "${metodoPago}"`,
        });
      } else {
        // Filtra y mapea los datos para agregar conceptos
        const nuevosConceptos = facturas
          .map((factura) => {
            const conceptos = [];

            if (factura.armazonImporte > 0) {
              const armazonSinIVA = (factura.armazonImporte / 1.16).toFixed(2); // Calcula el precio sin IVA
              conceptos.push({
                Cantidad: "1",
                CodigoUnidad: "H87",
                Unidad: "PZ",
                CodigoProducto: "",
                Producto: factura.armazon,
                PrecioUnitario: armazonSinIVA, // Usar el precio sin IVA
                Importe: armazonSinIVA, // Importe también sin IVA
                Descuento: "",
                TextoAdicional: factura.armazon,
              });
            }
            
            if (factura.lenteImporte > 0) {
              const lenteSinIVA = (factura.lenteImporte / 1.16).toFixed(2); // Calcula el precio sin IVA
              conceptos.push({
                Cantidad: "1",
                CodigoUnidad: "H87",
                Unidad: "PZ",
                CodigoProducto: "",
                Producto: factura.lente,
                PrecioUnitario: lenteSinIVA, // Usar el precio sin IVA
                Importe: lenteSinIVA, // Importe también sin IVA
                Descuento: "",
                TextoAdicional: factura.lente,
              });
            }
            
            if (factura.otroImporte > 0) {
              const otroSinIVA = (factura.otroImporte / 1.16).toFixed(2); // Calcula el precio sin IVA
              conceptos.push({
                Cantidad: "1",
                CodigoUnidad: "H87",
                Unidad: "PZ",
                CodigoProducto: "",
                Producto: factura.otro,
                PrecioUnitario: otroSinIVA, // Usar el precio sin IVA
                Importe: otroSinIVA, // Importe también sin IVA
                Descuento: "",
                TextoAdicional: factura.otro,
              });
            }

            return conceptos;
          })
          .flat(); // Aplana el array de arrays a un solo array

        // Resetea los conceptos anteriores y añade los nuevos
        const updatedConceptos = nuevosConceptos;

        // Recalcula SubTotal y Total
        const totalImportesConceptos = updatedConceptos.reduce(
          (acc, concepto) => acc + (parseFloat(concepto.Importe) || 0),
          0
        );

        setFactura((prevFactura) => ({
          ...prevFactura,
          Conceptos: updatedConceptos,
          SubTotal: totalImportesConceptos.toFixed(2),
          Total: (totalImportesConceptos * 1.16).toFixed(2),
        }));

        setFacturasGenerales(facturas);
      }
    } catch (error) {
      console.error("Error al obtener las facturas generales:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, section, index) => {
    const { name, value } = e.target;

    if (section === "Receptor") {
      // Actualiza los datos del receptor
      setFactura({
        ...factura,
        Receptor: {
          ...factura.Receptor,
          [name]: name !== "Correo" ? value.toUpperCase() : value,
        },
      });
    } else if (section === "Conceptos") {
      // Actualiza los datos de los conceptos
      const updatedConceptos = [...factura.Conceptos];
      updatedConceptos[index][name] = value.replace(/[^\d.]/g, "");

      if (name === "PrecioUnitario" || name === "Cantidad") {
        const unitario = parseFloat(
          updatedConceptos[index].PrecioUnitario || 0
        );
        const cantidad = parseFloat(updatedConceptos[index].Cantidad || 1);

        // Calcula el importe sin descuento
        const importeSinDescuento = (unitario * cantidad).toFixed(2);
        updatedConceptos[index].Importe = importeSinDescuento;
      }

      // Actualiza la factura con los nuevos valores de los conceptos
      setFactura({ ...factura, Conceptos: updatedConceptos });
      updateSubTotalYTotal(updatedConceptos);
    } else if (section === "Descuento") {
      // Aplica descuento general a cada concepto
      const descuentoGeneral = parseFloat(value) || 0;
      const updatedConceptos = factura.Conceptos.map((concepto) => {
        const cantidad = parseFloat(concepto.Cantidad) || 1;
        const precioUnitario = parseFloat(concepto.PrecioUnitario) || 0;
        const importeSinDescuento = (precioUnitario * cantidad).toFixed(2);

        // Calcula el descuento individual
        const descuentoCalculado = (
          importeSinDescuento *
          (descuentoGeneral / 100)
        ).toFixed(2);
        const importeConDescuento = (
          importeSinDescuento - descuentoCalculado
        ).toFixed(2);

        // Actualiza los valores del concepto
        return {
          ...concepto,
          Descuento: value,
          DescuentoCalculado: descuentoCalculado,
          Importe: importeConDescuento,
        };
      });

      // Actualiza la factura con los nuevos valores de los conceptos y recalcula el total
      setFactura((prevFactura) => ({
        ...prevFactura,
        Conceptos: updatedConceptos,
        Descuento: value,
      }));
      updateSubTotalYTotal(updatedConceptos);
    } else {
      setFactura({ ...factura, [name]: value });
    }
  };

  const updateSubTotalYTotal = (conceptos) => {
    // Recalcula el subtotal y el total basados en los importes de los conceptos
    const totalImportesConceptos = conceptos.reduce((acc, concepto) => {
      return acc + parseFloat(concepto.Importe || 0);
    }, 0);

    const subTotal = totalImportesConceptos.toFixed(2);
    const total = (totalImportesConceptos * 1.16).toFixed(2);

    setFactura((prevFactura) => ({
      ...prevFactura,
      SubTotal: subTotal,
      Total: total,
    }));
  };

  const handleBlur = (e, section, index) => {
    const { name, value } = e.target;

    const formattedValue = formatCurrency(value);

    if (section === "Conceptos") {
      const updatedConceptos = [...factura.Conceptos];
      updatedConceptos[index][name] = formattedValue;
      setFactura({ ...factura, Conceptos: updatedConceptos });
    } else {
      setFactura({ ...factura, [section]: formattedValue });
    }
  };

  const handleAddConcepto = () => {
    setFactura({
      ...factura,
      Conceptos: [
        ...factura.Conceptos,
        {
          Cantidad: "1",
          CodigoUnidad: "H87",
          Unidad: "PZ",
          CodigoProducto: "",
          Producto: "",
          PrecioUnitario: "",
          Importe: "",
          Descuento: "",
          TextoAdicional: "",
        },
      ],
    });
  };

  const handleRemoveConcepto = (index) => {
    if (factura.Conceptos.length > 1) {
      const updatedConceptos = factura.Conceptos.filter((_, i) => i !== index);
      setFactura((prevFactura) => {
        const totalImportesConceptos = updatedConceptos.reduce(
          (acc, concepto) => acc + (parseFloat(concepto.Importe) || 0),
          0
        );
        return {
          ...prevFactura,
          Conceptos: updatedConceptos,
          SubTotal: totalImportesConceptos.toFixed(2),
          Total: (totalImportesConceptos * 1.16).toFixed(2),
        };
      });
    }
  };

  const handleProductSelection = (e, index) => {
    const selectedProductId = e.target.value;
    const selectedProduct = productos.find(
      (producto) => producto.id === parseInt(selectedProductId)
    );

    const updatedConceptos = factura.Conceptos.map((concepto, i) =>
      i === index
        ? {
            ...concepto,
            CodigoProducto: selectedProduct
              ? selectedProduct.codigo_producto
              : "",
            Producto: selectedProduct ? selectedProduct.nombre : "",
            indexLista: selectedProduct ? selectedProduct.id : "",
          }
        : concepto
    );

    setFactura({ ...factura, Conceptos: updatedConceptos });
  };

  const handleAdditionalTextChange = (e, index) => {
    const updatedConceptos = [...factura.Conceptos];
    updatedConceptos[index].TextoAdicional = e.target.value.toUpperCase();
    updatedConceptos[index].Producto = `${
      productos.find(
        (producto) =>
          producto.codigo_producto === updatedConceptos[index].CodigoProducto
      ).nombre
    } ${e.target.value}`;
    setFactura({ ...factura, Conceptos: updatedConceptos });
  };

  const consultarClienteData = () => {
    if (factura.Receptor.RFC) {
      axios
        .get(`/facturacion/datosCliente/${factura.Receptor.RFC}`)
        .then((response) => {
          if (response.data.contentClient) {
            const dataClient = response.data.contentClient;
            setFactura((prevFactura) => ({
              ...prevFactura,
              Receptor: {
                ...prevFactura.Receptor,
                RFC: dataClient.rfc,
                NombreRazonSocial: dataClient.nombreCompleto,
                CodigoPostal: dataClient.codigoPostal,
                Correo: dataClient.email,
              },
            }));
          } else {
            Swal.fire({
              icon: "warning",
              title: "¡No se encontró al cliente!",
              text: "Favor de llenar los datos necesarios para generar la factura.",
            });
            setFactura((prevFactura) => ({
              ...prevFactura,
              Receptor: {
                ...prevFactura.Receptor,
                NombreRazonSocial: "",
                CodigoPostal: "",
                Correo: "",
              },
            }));
          }
        })
        .catch((error) => {
          console.error("Error al obtener los datos del cliente:", error);
        });
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post("/facturacion/crearFactura", factura)
      .then((response) => {
        console.log("Respuesta a factura obtenida:", response.data);
        if (response.data.estatus) {
          if (response.data.estatus.codigo !== "000") {
            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: `${response.data.estatus.descripcion}`,
              text: `${response.data.estatus.informacionTecnica}`,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: `${response.data.estatus.descripcion}`,
              text: "Se ha enviado la factura al correo del cliente.",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: "Abrir Factura",
              denyButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              denyButtonColor: "#6c757d",
              reverseButtons: true,
              allowOutsideClick: false,
            }).then((result) => {
              setLoading(false);
              if (result.isConfirmed) {
                // Manejar el clic en "Abrir Factura"
                const base64PDF = response.data.cfdiTimbrado.respuesta.pdf;
                const pdfWindow = window.open("", "_blank");
                pdfWindow.document.write(`
                  <iframe width='100%' height='100%' style='position:absolute; top:0; left:0;' src='data:application/pdf;base64,${base64PDF}'></iframe>
                `);
                pdfWindow.document.close();
                window.location.reload();
              } else if (result.isDenied) {
                // Manejar el clic en "Continuar"
                window.location.reload();
              } else if (result.isDismissed) {
                // Manejar el clic en "Cancelar"
                // Puedes añadir lógica aquí si necesitas manejar la cancelación
              }
            });
          }
        } else {
          const errors = response.data.errors;

          // Construir el mensaje de errores concatenando todos los mensajes de error
          let errorMessage = "";
          for (const [key, messages] of Object.entries(errors)) {
            errorMessage += messages.join(", ") + "\n";
          }
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: "Ocurrió un error",
            text: errorMessage.trim(),
            footer: "<span>Contacte a su administrador</span>",
          });
        }
      })
      .catch((error) => {
        console.error("Error al crear la factura:", error);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Ocurrió un error",
          text: "Contacte a su administrador",
        });
      });
  };

  const formatCurrency = (value) => {
    if (!value) return "";
    // Convierte el valor a un número flotante y asegura dos decimales
    const number = parseFloat(value).toFixed(2);
    // Agrega el símbolo `$` al principio y formatea el número con comas
    return `${number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  return (
    <div>
      <Header />
      <div
        style={{
          position: "absolute",
          right: "100px",
          top: "120px",
          border: '1px solid gray',
          padding: '15px',
          borderRadius: '10px'
        }}
      >
        <p style={{width: '350px', fontSize: '18px'}}>
          Seleccione el metodo de pago para cargar <b>automáticamente</b> las
          transacciones de público en general:
        </p>
        <select
          name=""
          id="metodo"
          style={{
            width: "fit-content"
          }}
          className="form-select"
          onChange={(e) => facturasGeneralesFunction(e.target.value)}
        >
          <option value="" defaultValue>
            Seleccione metodo de pago
          </option>
          <option value="Efectivo">Efectivo</option>
          <option value="Credito">Credito</option>
          <option value="Debito">Debito</option>
          <option value="Transferencia">Transferencia</option>
        </select>
      </div>
      <form
        style={{ width: "700px", margin: "40px auto" }}
        onSubmit={handleSubmit}
      >
        <p style={{ fontSize: "38px", fontWeight: "700" }}>
          Generar Factura - Público en General
        </p>
        <div className="mb-3">
          <h4>Datos del Receptor</h4>
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="rfc" className="form-label">
                RFC
              </label>
              <input
                type="text"
                className="form-control"
                name="RFC"
                value={factura.Receptor.RFC.toUpperCase()}
                onChange={(e) => handleInputChange(e, "Receptor")}
                onBlur={() => consultarClienteData()}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="nombreRazonSocial" className="form-label">
                Nombre o Razón Social
              </label>
              <input
                type="text"
                className="form-control"
                name="NombreRazonSocial"
                value={factura.Receptor.NombreRazonSocial}
                onChange={(e) => handleInputChange(e, "Receptor")}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="regimenFiscal" className="form-label">
                Régimen Fiscal
              </label>
              <select
                className="form-select"
                name="RegimenFiscal"
                value={factura.Receptor.RegimenFiscal}
                onChange={(e) => handleInputChange(e, "Receptor")}
              >
                <option value="">Selecciona un régimen fiscal</option>
                <option value="601">
                  601 - General de Ley Personas Morales
                </option>
                <option value="603">
                  603 - Personas Físicas con Actividades Empresariales y
                  Profesionales
                </option>
                <option value="605">
                  605 - Sueldos y Salarios e Ingresos Asimilados a Salarios
                </option>
                <option value="606">606 - Arrendamiento</option>
                <option value="607">
                  607 - Régimen de Enajenación o Adquisición de Bienes
                </option>
                <option value="608">608 - Demás ingresos</option>
                <option value="609">609 - Consolidación</option>
                <option value="610">
                  610 - Residentes en el Extranjero sin Establecimiento
                  Permanente en México
                </option>
                <option value="611">
                  611 - Ingresos por Dividendos (Socios y Accionistas)
                </option>
                <option value="612">
                  612 - Personas Físicas con Actividades Empresariales
                </option>
                <option value="614">
                  614 - Régimen Simplificado de Confianza
                </option>
                <option value="615">615 - Ingresos por Intereses</option>
                <option value="616">616 - Sin obligaciones fiscales</option>
                <option value="620">
                  620 - Sociedades Cooperativas de Producción que optan por
                  diferir sus ingresos
                </option>
                <option value="621">621 - Incorporación Fiscal</option>
                <option value="622">
                  622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
                </option>
                <option value="623">
                  623 - Opcional para Grupos de Sociedades
                </option>
                <option value="624">624 - Coordinados</option>
                <option value="625">
                  625 - Régimen de las Actividades Empresariales con ingresos a
                  través de Plataformas Tecnológicas
                </option>
                <option value="626">
                  626 - Régimen Simplificado de Confianza
                </option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="codigoPostal" className="form-label">
                Código Postal <b>(Cliente)</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="CodigoPostal"
                value={factura.Receptor.CodigoPostal}
                onChange={(e) => handleInputChange(e, "Receptor")}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="usoCFDI" className="form-label">
                Uso de CFDI
              </label>
              <select
                className="form-select"
                name="UsoCFDI"
                value={factura.Receptor.UsoCFDI}
                onChange={(e) => handleInputChange(e, "Receptor")}
              >
                <option value="">Selecciona un uso de CFDI</option>
                {usosCFDI.map((uso) => (
                  <option key={uso.codigo} value={uso.codigo}>
                    {uso.codigo} - {uso.descripcion}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="Correo" className="form-label">
                Correo
              </label>
              <input
                type="text"
                className="form-control"
                name="Correo"
                value={factura.Receptor.Correo}
                onChange={(e) => handleInputChange(e, "Receptor")}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="CPLugarExpedicion" className="form-label">
                Código Postal <b>(LugarExpedición)</b>
              </label>
              <input
                type="text"
                className="form-control"
                name="CPLugarExpedicion"
                value={factura.CPLugarExpedicion}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
        <b style={{ fontSize: "14px" }}>
          El código en los nombres de los productos no aparecerá en el texto
          descriptivo, solo es referenca.
        </b>
        {factura.Conceptos.map((concepto, index) => (
          <div className="mb-3 border p-3" key={index}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>Concepto {index + 1}</h5>
              {factura.Conceptos.length > 1 && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveConcepto(index)}
                >
                  Eliminar
                </button>
              )}
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <label htmlFor={`producto-${index}`} className="form-label">
                  Producto
                </label>
                <select
                  className="form-select"
                  value={concepto.indexLista}
                  onChange={(e) => handleProductSelection(e, index)}
                >
                  <option value="">Selecciona un producto</option>
                  {productos.map((producto) => (
                    <option key={producto.id} value={producto.id}>
                      {producto.nombre} ({producto.codigo_producto})
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-8">
                <label
                  htmlFor={`textoAdicional-${index}`}
                  className="form-label"
                >
                  Texto Adicional
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={concepto.TextoAdicional}
                  onChange={(e) => handleAdditionalTextChange(e, index)}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-3">
                <label htmlFor={`cantidad-${index}`} className="form-label">
                  Cantidad
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="Cantidad"
                  value={concepto.Cantidad}
                  onChange={(e) => handleInputChange(e, "Conceptos", index)}
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor={`precioUnitario-${index}`}
                  className="form-label"
                >
                  Precio Unitario
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="PrecioUnitario"
                  value={concepto.PrecioUnitario.toLocaleString("es-MX", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  onChange={(e) => handleInputChange(e, "Conceptos", index)}
                  onBlur={(e) => handleBlur(e, "Conceptos", index)}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor={`importe-${index}`} className="form-label">
                  Importe
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="Importe"
                  value={concepto.Importe}
                  onChange={(e) => handleInputChange(e, "Conceptos", index)}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor={`descuento-${index}`} className="form-label">
                  Descuento
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="Descuento"
                  value={concepto.Descuento}
                  onChange={(e) => handleInputChange(e, "Conceptos", index)}
                />
              </div>
            </div>
          </div>
        ))}

        <div className="mb-3">
          <h4>Forma y Método de Pago</h4>
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="formaPago" className="form-label">
                Forma de Pago
              </label>
              <select
                className="form-select"
                name="FormaPago"
                value={factura.FormaPago}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Selecciona una forma de pago</option>
                {formasPago.map((forma) => (
                  <option key={forma.codigo} value={forma.codigo}>
                    {forma.codigo} - {forma.descripcion}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="metodoPago" className="form-label">
                Método de Pago
              </label>
              <select
                className="form-select"
                name="MetodoPago"
                value={factura.MetodoPago}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Selecciona un método de pago</option>
                {metodosPago.map((metodo) => (
                  <option key={metodo.codigo} value={metodo.codigo}>
                    {metodo.codigo} - {metodo.descripcion}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <h4>Totales</h4>
          <div className="row mb-2">
            <div className="col-md-4">
              <label htmlFor="subTotal" className="form-label">
                SubTotal
              </label>
              <input
                type="text"
                className="form-control"
                name="SubTotal"
                value={factura.SubTotal}
                readOnly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="descuento" className="form-label">
                Descuento
              </label>
              <input
                type="text"
                className="form-control"
                name="Descuento"
                value={factura.Descuento}
                onChange={(e) => handleInputChange(e, "Descuento")}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="total" className="form-label">
                Total
              </label>
              <input
                type="text"
                className="form-control"
                name="Total"
                value={factura.Total}
                onChange={(e) => handleInputChange(e, "Total")}
                onBlur={(e) => handleBlur(e, "Total")}
              />
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-success"
          onClick={handleAddConcepto}
        >
          Agregar Concepto
        </button>

        <button
          type="submit"
          className="btn btn-primary"
          style={{ margin: "0 0 0 20px" }}
        >
          Enviar Factura
        </button>
        <Loader isLoading={loading} />
      </form>
    </div>
  );
};

export default CrearFacturaGeneral;
