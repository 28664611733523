import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import logo from "../../images/LogoTransparente.png";
import Header from "../Header";
import "../../index.css";

const DetallesPedido = () => {
  const [transaccionesList, setTransacciones] = useState([]);
  const [hashId, setHash] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const pedidoId = location.state?.pedidoId;

  useEffect(() => {
    if (!pedidoId) {
      navigate("/dashboard/pedidos");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/pedidoTransacciones/${pedidoId}`
        );
        setTransacciones(response.data);
        
        console.log(transaccionesList);
      } catch (error) {
        console.error("Error al obtener los pedidos:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron obtener las transacciones del pedido.",
        });
      }
    };

    const fetchDataPedido = async () => {
      try {
        const response = await axios.get(
          `/pedidoPorId/${pedidoId}`
        );
        setHash(response.data[0].HashId);
      } catch (error) {
        console.error("Error al obtener los pedidos:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron obtener las transacciones del pedido.",
        });
      }
    };

    fetchData();
    fetchDataPedido();
  }, [pedidoId, navigate]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const estilosTitulo = {
    color: "black",
    margin: "25px 0",
    padding: "20px",
    border: "1px solid gray",
    borderRadius: "20px",
    width: "fit-content",
    backgroundColor: "white"
  };

  
  const handleRegresar = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header />

      <div style={{ margin: "50px", display: 'grid' }}>
      <button
        className="btn btn-sm btn-primary"
        onClick={handleRegresar}
        style={{ width: "200px", justifySelf: "end", marginRight: "1vw",  marginTop: '0px' }}
      >
        Regresar
      </button>
        <h2 style={estilosTitulo}>
          Transacciónes del pedido con ID:{" "}
          <span style={{ fontWeight: "700", fontSize: "20px" }}>{hashId}</span>
        </h2>
        <table className="table table-dark" style={{ borderRadius: "20px" }}>
          <thead>
            <tr>
              <th style={{ borderTopLeftRadius: "20px" }}>Fecha de Creación</th>
              <th>Cliente</th>
              <th>Sucursal</th>
              <th>Último Modificador</th>
              <th>Importe</th>
              <th>Saldo Pendiente</th>
              <th>Total</th>
              <th>Forma de pago</th>
              <th style={{ borderTopRightRadius: "20px" }}>Tipo de Pedido</th>
            </tr>
          </thead>
          <tbody>
            {transaccionesList.map((transaccion) => (
              <tr key={transaccion.id} className="tablaPedidos">
                <td style={{ whiteSpace: "nowrap" }}>
                  {formatDate(transaccion.createDate)}
                </td>
                <td>{transaccion.nombreCliente}</td>
                <td style={{ maxWidth: "350px" }}>
                  {transaccion.direccionSucursal}
                </td>
                <td>{transaccion.nombreCreador}</td>
                <td>${transaccion.importe.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>${transaccion.saldoPendiente.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>${transaccion.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{transaccion.formaPago}</td>
                <td>{capitalizeFirstLetter(transaccion.tipoPago)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetallesPedido;
