import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import "../../index.css";

const Visita = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cliente = location.state?.cliente;
  const sucursales = location.state?.sucursales;
  if (!cliente) {
    navigate("/dashboard/clientes");
  }

  const [dificultadLejos, setDificultadLejos] = useState("No");
  const [dificultadCerca, setDificultadCerca] = useState("No");
  const [dolorCabeza, setDolorCabeza] = useState("No");
  const [irritacion, setIrritacion] = useState("No");
  const [diabetes, setDiabetes] = useState("No");
  const [hipertension, setHipertension] = useState("No");

  // Estado para la tabla de prescripción
  const [esfOd, setEsfOd] = useState("");
  const [esfOi, setEsfOi] = useState("");
  const [cilOd, setCilOd] = useState("");
  const [cilOi, setCilOi] = useState("");
  const [ejeOd, setEjeOd] = useState("");
  const [ejeOi, setEjeOi] = useState("");
  const [addOd, setAddOd] = useState("");
  const [addOi, setAddOi] = useState("");
  const [avOd, setAvOd] = useState("");
  const [avOi, setAvOi] = useState("");
  const [cvOd, setCvOd] = useState("");
  const [cvOi, setCvOi] = useState("");
  const [retinosOd, setRetinosOd] = useState("");
  const [retinosOi, setRetinosOi] = useState("");
  const [queratoOd1, setQueratoOd1] = useState("");
  const [queratoOd2, setQueratoOd2] = useState("");
  const [queratoOd3, setQueratoOd3] = useState("");
  const [queratoOi1, setQueratoOi1] = useState("");
  const [queratoOi2, setQueratoOi2] = useState("");
  const [queratoOi3, setQueratoOi3] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [color, setColor] = useState("");
  const [lenteContactoDes, setLenteContactoDes] = useState("");
  const [diData, setDiData] = useState("");
  const [aoData, setAoData] = useState("");
  const [optometrista, setOptometrista] = useState("FLAVIANO PELAYO SALAZAR");
  //const [cedula, setCedula] = useState("9330082");
  const [cedula, setCedula] = useState("");
  const [fotos, setFotos] = useState([]);

  const handleFileChange = (e) => {
    setFotos([...e.target.files]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const branchId = parseInt(localStorage.getItem("branchId"), 10);
    const sucursal = sucursales.find((suc) => suc.id === branchId);
    const requestData = new FormData();
    
    requestData.append("idCliente", cliente.id);
    requestData.append("nombreCliente", cliente.nombreCompleto);
    requestData.append("idSucursal", localStorage.getItem("branchId"));
    requestData.append("nombreSucursal", sucursal.direccion);
    requestData.append("dificultadLejos", dificultadLejos);
    requestData.append("dificultadCerca", dificultadCerca);
    requestData.append("dolorCabeza", dolorCabeza);
    requestData.append("irritacion", irritacion);
    requestData.append("diabetes", diabetes);
    requestData.append("hipertension", hipertension);
    requestData.append("esfOd", esfOd);
    requestData.append("esfOi", esfOi);
    requestData.append("cilOd", cilOd);
    requestData.append("cilOi", cilOi);
    requestData.append("ejeOd", ejeOd);
    requestData.append("ejeOi", ejeOi);
    requestData.append("addOd", addOd);
    requestData.append("addOi", addOi);
    requestData.append("avOd", avOd);
    requestData.append("avOi", avOi);
    requestData.append("cvOd", cvOd);
    requestData.append("cvOi", cvOi);
    requestData.append("retinosOd", retinosOd);
    requestData.append("retinosOi", retinosOi);
    requestData.append("queratoOd1", queratoOd1);
    requestData.append("queratoOd2", queratoOd2);
    requestData.append("queratoOd3", queratoOd3);
    requestData.append("queratoOi1", queratoOi1);
    requestData.append("queratoOi2", queratoOi2);
    requestData.append("queratoOi3", queratoOi3);
    requestData.append("observaciones", observaciones);
    requestData.append("color", color);
    requestData.append("lenteContactoDes", lenteContactoDes);
    requestData.append("diData", diData);
    requestData.append("aoData", aoData);
    requestData.append("optometrista", optometrista);
    requestData.append("cedula", cedula);
    requestData.append("createDate", new Date().toISOString()); // Fecha actual en formato ISO
  
    fotos.forEach((foto, index) => {
      requestData.append('fotos', foto); // Asegúrate de que el nombre del campo es 'fotos'
    });
    try {
      const response = await axios.put(
        "/visitas/crear",
        requestData,
        {
          headers: {
            name: localStorage.getItem("name"),
            lastname: localStorage.getItem("lastName"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        Swal.fire({
          title: "¡Visita creada correctamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        }).then(() => {
          navigate("/dashboard/clientes");
        });
      } else {
        Swal.fire("Error", "No se pudo crear la visita", "error");
      }
    } catch (error) {
      console.error("Error al crear la visita:", error);
      Swal.fire("Error", "Hubo un problema al crear la visita", "error");
    }
  };

  function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }

  const inputTablaOptometria = {
    border: "1px solid black",
    borderRadius: "5px",
    borderColor: "gray",
    width: "65px",
  };

  return (
    <div
      style={{
        marginTop: "50px",
        color: "black",
        padding: "0 50px",
        display: "grid",
      }}
    >
      <Header></Header>
      <p style={{ fontSize: "36px", textAlign: "center", fontWeight: "700", textDecoration: 'underline' }}>
        Nueva Visita:{" "}
        <span style={{ fontWeight: "500" }}>{cliente?.nombreCompleto}</span>
      </p>
      <h3
        style={{ fontWeight: "700", textAlign: "center", paddingTop: "10px" }}
      >
        Prescripción de Optometría
      </h3>
      <form
        onSubmit={handleSubmit}
        style={{ display: "grid", justifyItems: "center", paddingTop: "20px" }}
      >
        <div
          style={{
            display: "grid",
            justifyItems: "center",
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div div style={{ marginRight: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginBottom: "0px",
                }}
              >
                Optometrista
              </div>
              <input
                type="text"
                value={optometrista}
                onChange={(e) => setOptometrista(e.target.value)}
                placeholder="Ingrese nombre"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  justifySelf: "center",
                  textAlign: "center",
                  marginBottom: "30px",
                  width: '250px'
                }}
              />
            </div>

            <div div style={{ marginLeft: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                Cedula Prof.
              </div>
              <input
                type="text"
                value={cedula}
                onChange={(e) => setCedula(e.target.value)}
                placeholder="Ingrese cedula"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "170px",
                  justifySelf: "center",
                  textAlign: "center",
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>
                Refiere dificultad a lo lejos
              </span>
              <select
                className="form-control form-select"
                id="dificultadLejos"
                value={dificultadLejos}
                onChange={(e) => setDificultadLejos(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>
                Refiere dificultad a lo cerca
              </span>
              <select
                className="form-control form-select"
                id="dificultadCerca"
                value={dificultadCerca}
                onChange={(e) => setDificultadCerca(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>Dolor de cabeza</span>
              <select
                className="form-control form-select"
                id="dolorCabeza"
                value={dolorCabeza}
                onChange={(e) => setDolorCabeza(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>Irritación</span>
              <select
                className="form-control form-select"
                id="irritacion"
                value={irritacion}
                onChange={(e) => setIrritacion(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>Diabetes</span>
              <select
                className="form-control form-select"
                id="diabetes"
                value={diabetes}
                onChange={(e) => setDiabetes(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div
              style={{
                width: "220px",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <span style={{ fontWeight: "700" }}>Hipertensión</span>
              <select
                className="form-control form-select"
                id="hipertension"
                value={hipertension}
                onChange={(e) => setHipertension(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>
          </div>
        </div>
        <div
          style={{
            fontWeight: "700",
            fontSize: "17px",
            textAlign: "center",
            marginTop: "0px",
          }}
        >
          Observaciones
        </div>
        <textarea
          type="text"
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
          placeholder="Ingrese sus observaciones"
          style={{
            border: "1px solid black",
            padding: "5px",
            borderRadius: "7px",
            width: "300px",
            textAlign: "center",
            justifySelf: "center",
          }}
        />

        <div style={{ margin: "60px 0 0 0px", display: "grid", position: 'relative' }}>
          <div
            style={{ fontWeight: "700", fontSize: "20px", textAlign: "center", position: 'absolute', top: '-5%', left:'15%' }}
          >
            Graduación
          </div>
          <div style={{ display: "grid" }}>
            <div style={{ display: "flex", justifyItems: "center" }}>
              <table>
                <thead>
                  <tr>
                    <th> &nbsp;</th>
                    <th style={{ textAlign: "center" }}>ESF</th>
                    <th style={{ textAlign: "center" }}>CIL</th>
                    <th style={{ textAlign: "center" }}>EJE</th>
                    <th style={{ textAlign: "center" }}>ADD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>OD</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={esfOd}
                        onChange={(e) => setEsfOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cilOd}
                        onChange={(e) => setCilOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={ejeOd}
                        onChange={(e) => setEjeOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={addOd}
                        onChange={(e) => setAddOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>OI</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={esfOi}
                        onChange={(e) => setEsfOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cilOi}
                        onChange={(e) => setCilOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={ejeOi}
                        onChange={(e) => setEjeOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={addOi}
                        onChange={(e) => setAddOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style={{ margin: "0px 0 0 40px" }}>
                <thead>
                  <tr>
                    <th> &nbsp;&nbsp;</th>
                    <th style={{ textAlign: "center" }}>A/V</th>
                    <th></th>
                    <th style={{ textAlign: "center" }}>C/V</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "right" }}>OD 20/</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={avOd}
                        onChange={(e) => setAvOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;OD 20/</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cvOd}
                        onChange={(e) => setCvOd(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>OI 20/</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={avOi}
                        onChange={(e) => setAvOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      &nbsp;&nbsp;&nbsp;OI 20/
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cvOi}
                        onChange={(e) => setCvOi(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div
                style={{
                  textAlign: "center",
                  display: "grid",
                  marginTop: "30px",
                  marginLeft: '12%'
                }}
              >
                <h5 style={{ fontWeight: "700" }}>Retinoscopia</h5>
                <table style={{ justifySelf: "center" }}>
                  <thead>
                    <tr>
                      <th>OD</th>
                      <th>OI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={retinosOd}
                          onChange={(e) => setRetinosOd(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={retinosOi}
                          onChange={(e) => setRetinosOi(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  position: "relative",
                  marginTop: "30px",
                  textAlign: "center",
                  display: "grid",
                }}
              >
                <h5 style={{ fontWeight: "700" }}>Queratometría</h5>
                <table style={{ margin: "0 0 0 0px", justifySelf: "center" }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        OD / &nbsp;
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOd1}
                          onChange={(e) => setQueratoOd1(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOd2}
                          onChange={(e) => setQueratoOd2(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        &nbsp;X&nbsp;
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOd3}
                          onChange={(e) => setQueratoOd3(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        OI / &nbsp;&nbsp;
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOi1}
                          onChange={(e) => setQueratoOi1(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOi2}
                          onChange={(e) => setQueratoOi2(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        &nbsp;X&nbsp;
                        <input
                          type="number"
                          step="0.25"
                          min="-1000"
                          max="1000"
                          value={queratoOi3}
                          onChange={(e) => setQueratoOi3(e.target.value)}
                          style={inputTablaOptometria}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifySelf: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                DI
              </div>
              <input
                type="text"
                value={diData}
                onChange={(e) => setDiData(e.target.value)}
                placeholder="Ingrese DI"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "130px",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              />
            </div>

            <div style={{ marginLeft: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                AO
              </div>
              <input
                type="text"
                value={aoData}
                onChange={(e) => setAoData(e.target.value)}
                placeholder="Ingrese AO"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "130px",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              />
            </div>
          </div>
          </div>

          <div
            style={{
              fontWeight: "700",
              fontSize: "20px",
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            Departamento / Empleado
          </div>
          <textarea
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="Ingrese Departamento / Empleado"
            style={{
              border: "1px solid black",
              padding: "5px",
              borderRadius: "7px",
              width: "400px",
              textAlign: "left",
              justifySelf: "center",
              height: '100px'
            }}
          />

          <div
            style={{
              fontWeight: "700",
              fontSize: "20px",
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            Lente de contacto (Descripción)
          </div>
          <input
            type="text"
            value={lenteContactoDes}
            onChange={(e) => setLenteContactoDes(e.target.value)}
            placeholder="Ingrese descripción"
            style={{
              border: "1px solid black",
              padding: "5px",
              borderRadius: "7px",
              width: "300px",
              textAlign: "center",
              justifySelf: "center",
            }}
          />

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>
            <label htmlFor="foto" style={{ fontWeight: "700", fontSize: "20px", textAlign: "center" }}>
              Cargar Fotos
            </label>
            <input
              type="file"
              id="foto"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              margin: "20px 0 20px 0px",
              justifySelf: "center",
            }}
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Visita;
