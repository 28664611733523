import React, { useState } from "react";
import axios from "../api/axiosConfig";
import Swal from "sweetalert2";
import logo from "../images/LogoTransparente.png";
import OjoVer from "../images/OjoVer.png";
import OjoQuitar from "../images/OjoQuitar.png";

const Login = () => {
  const styleContainerP = {
    padding: "20px",
    borderRadius: "20px",
    border: "1px solid black",
    width: "40%",
    marginTop: "20px",
    justifySelf: "center",
    textAlign: "center",
  };

  const containerFormParent = {
    display: "grid",
  };

  const inputStyles = {
    textAlign: "center",
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [flagPassword, setFlagPassword] = useState(false);

  const handleLogin = () => {
    axios
      .post("/login", {
        username: username,
        password: password,
      })
      .then((response) => {
        console.log(response);
        // Manejar la respuesta del servidor
        if (response.data.success) {
          localStorage.setItem("token", response.data.tk);

          localStorage.setItem("idUser", response.data.userData.id);
          localStorage.setItem("branchId", response.data.userData.branchId);
          localStorage.setItem("name", response.data.userData.name);
          localStorage.setItem("lastName", response.data.userData.lastName);
          localStorage.setItem(
            "secondLastName",
            response.data.userData.secondLastName
          );
          localStorage.setItem("userType", response.data.userData.userType);

          Swal.fire({
            title: "¡Ingreso exitoso!",
            text: `Bienvenido ${response.data.userData.name} ${response.data.userData.lastName}`,
            icon: "success",
            confirmButtonText: "Ir al dashboard",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/dashboard";
            }
          });
        } else {
          if (response.data.timeDeny) {
            Swal.fire({
              title: "¡No se inicio sesión!",
              text: `Esta fuera de su horario disponible para iniciar sesión.`,
              icon: "warning",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              title: "¡No se inicio sesión!",
              text: `Las credenciales son invalidas o el usuario esta inactivo, contacte a su administrador.`,
              icon: "warning",
              confirmButtonText: "Ok",
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error al iniciar sesión:", error);
        Swal.fire({
          title: "¡Ocurrió un error!",
          text: `Las credenciales son invalidas o el usuario esta inactivo, contacte a su administrador.`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div className="container mt-5" style={containerFormParent}>
      <img src={logo} alt="" style={{ width: "15%", justifySelf: "center" }} />
      <h3
        className="text-dark"
        style={{
          placeSelf: "center",
          marginTop: "40px",
          width: "300px",
          textAlign: "center",
        }}
      >
        Administrador Opticas Pelayo
      </h3>
      <form style={styleContainerP}>
        <div className="mb-3">
          <b htmlFor="username" className="form-label text-dark">
            Usuario:
          </b>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={inputStyles}
            placeholder="Ingresa tu usuario"
          />
        </div>
        <div className="mb-3">
          <b htmlFor="password" className="form-label text-dark">
            Contraseña:
          </b>
          <div style={{ position: "relative" }}>
            <input
              type={flagPassword ? "text" : "password"}
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyles}
              placeholder="Ingresa tu contraseña"
            />
            {!flagPassword ? (
              <img
                src={OjoVer}
                alt=""
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "3px",
                  cursor: "pointer",
                }}
                width="30"
                onClick={(e) => setFlagPassword(true)}
              />
            ) : (
              <img
                src={OjoQuitar}
                alt=""
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "3px",
                  cursor: "pointer",
                }}
                width="30"
                onClick={(e) => setFlagPassword(false)}
              />
            )}
          </div>
        </div>
        <button type="button" className="btn btn-primary" onClick={handleLogin}>
          Iniciar sesión
        </button>
      </form>
    </div>
  );
};

export default Login;
