import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import "../../print.css";
import QRCode from "qrcode.react";
import logo from "../../images/LogoTransparente.png";

const NotaVentaView = () => {
  const [NotaVenta, setNotaVenta] = useState(null);
  const [Graduaciones, setGraduaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [verGraduacion, setVerGraduacion] = useState("none");
  const [verPrecios, setVerPrecios] = useState("none");

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const id = params.get("id");
  const tipoNota = params.get("tipoNota");
  const EsVisita = params.get("visita");
  const [qrLink, setQrLink] = useState("");

  useEffect(() => {
    //Aqui vamos a modificar el formato de salida del pdf dependiendo que tipo de nota queremos imprimir al momento.
    switch(tipoNota) {
      case "0": { //Cliente con todos los datos pero sin graduacion.
        setVerGraduacion("none");
        setVerPrecios("flex");
        break;
      }
      case "1": { // Administrador con todos los datos
        setVerGraduacion("block");
        setVerPrecios("flex");
        break;
      }
      case "2": { //Laboratorio con todos los datos menos los precios
        setVerGraduacion("block");
        setVerPrecios("none");
        break;
      }
      case "3": { //Extra Personal 
        setVerGraduacion("None");
        setVerPrecios("none");
        break;
      }
      default: {
        setVerGraduacion("block");
        setVerPrecios("flex");
        break;
      }
    }
  }, [tipoNota]);

  useEffect(() => {
    document.body.style.paddingTop = "0";
    const fetchUltimaNotaVenta = async () => {
      try {
        if (EsVisita !== "true") {
          const response = await axios.get(
            `/pedidoCompletoPorId/${id}`
          );
          setNotaVenta(response.data[0]);
          console.log(response.data[0])
          const dataGraduacion = await axios.get(
            `/LeerGraduaciones/${response.data[0].idCliente}`
          );
          setGraduaciones(dataGraduacion.data);
          setQrLink(`${window.origin}/=` + response.data[0].HashId); 
          setLoading(false);
          
        }else{
          const response = await axios.get(
            `/visitasPorCliente/${id}`
          );
          setNotaVenta(response.data[0]);
          const dataGraduacion = await axios.get(
            `/LeerGraduaciones/${response.data[0].IdCliente}`
          );
          setGraduaciones(dataGraduacion.data);
          setQrLink(`${window.origin}/=` + response.data[0].HashId); 
          setLoading(false);
          
        }
        
      } catch (error) {
        console.error("Error al obtener el ultimo pedido:", error);
        Swal.fire({
          icon: "warning",
          title: "Error al obtener el ultimo pedido",
          text: "Hubo un problema al cargar los detalles de el ultimo pedido. Por favor, inténtalo de nuevo más tarde.",
        });
        setLoading(false);
      }
    };

    fetchUltimaNotaVenta();
  }, [id]);

  if (loading) {
    return <div>Cargando historico...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const formatDateWithoutTime = (dateString) => {
    const formattedDate = formatDate(dateString);
    return formattedDate.split(",")[0];
  };

  function formatNumber(number) {
    number = parseInt(number);
    const [integerPart, decimalPart] = number.toFixed(2).split(".");
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${integerWithCommas}.${decimalPart}`;
  }

  function capitalize(text) {
    if (!text) return text; 
    return text
      .toLowerCase() 
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
  }

  const fecha = new Date();

  // Definir las opciones de formato para el día, mes y año
  const opciones = { day: 'numeric', month: 'numeric', year: 'numeric' };

  // Formatear la fecha
  const fechaFormateada = fecha.toLocaleDateString('es-ES', opciones);

  const fechaEntregaPrevista = new Date(NotaVenta.fechaEntregaPrevista);
  // Formatear la fecha
  const fechaFormateadaEntrega = fechaEntregaPrevista.toLocaleDateString('es-ES', opciones);

  return (
    <div style={{fontSize: "13px", width: '70mm', position: 'relative', fontFamily: 'Arial, sans-serif', fontWeight: '800'}} id="content">
      {EsVisita === "true" ? (
         <div style={{ fontSize: "13px", width: '70mm', position: 'relative', fontFamily: 'Arial, sans-serif', fontWeight: '800' }} id="content">
         <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: 'bold' }}>
           <strong>{NotaVenta.tipoTicketFis === "Nota Simple" ? "Nota de Venta" : "Comprobante"}</strong>
         </div>
         <div style={{ display: 'flex', width: '100%', marginBottom: "10px" }}>
           <img src={logo} style={{ width: "115px", margin: 'auto' }} />
         </div>
         <div style={{ marginBottom: "5px", textAlign: 'center' }}>
           <strong>Fecha:</strong> {fechaFormateada}
         </div>
         {NotaVenta.tipoTicketFis !== "Nota Simple" && (
           <>
             <div style={{ marginBottom: "5px", textAlign: 'center', fontSize: '10px' }}>
               <strong>RFC:</strong> {NotaVenta.rfc}
             </div>
             <div style={{ marginBottom: "5px", textAlign: 'center', fontSize: '10px' }}>
               {NotaVenta.razonSocial.toUpperCase()}
             </div>
           </>
         )}
         <div style={{ marginBottom: "5px", textAlign: 'center', padding: '0 0.5mm', fontSize: '10px' }}>
           {NotaVenta.direccionSucursal}
         </div>
         <div style={{ marginBottom: "5px", textAlign: 'center' }}>
           <strong>Cliente:</strong> {NotaVenta.nombreCliente}
         </div>
         <div style={{ marginBottom: "5px", textAlign: 'center' }}>
           <strong>Teléfono Cliente:</strong> {NotaVenta.telefono}
         </div>
         {Graduaciones.length > 0 ? (
          Graduaciones.map((Graduacion, index) => (
            <div style={{ marginTop: "10px", display: verGraduacion, marginBottom: '20px'}} key={index}>
              <div style={{ textAlign: "center", marginBottom: "10px", fontSize: '14px', fontWeight: 'bold', }}>
                <strong style={{textDecoration: 'underline'}}>Datos Laboratorio  {index + 1}</strong>
              </div>
              <div style={{textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center'}}>
                <strong style={{marginLeft: '30px', fontWeight: '800', fontSize: '14px' }}>Graduación</strong>
              </div>
              <table style={{ width: '80%', marginLeft: '25px', fontSize: '13px', fontWeight: '900', textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ textAlign: 'center', fontWeight: '800' }}>ESF</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>CIL</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>EJE</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>ADD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: '800' }}>OD</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EsfOd > 0 ? "+" + Graduacion.EsfOd : Graduacion.EsfOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.CilOd > 0 ? "+" + Graduacion.CilOd : Graduacion.CilOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EjeOd > 0 ? "+" + Graduacion.EjeOd : Graduacion.EjeOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.AddOd > 0 ? "+" + Graduacion.AddOd : Graduacion.AddOd}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '800' }}>OI</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EsfOi > 0 ? "+" + Graduacion.EsfOi : Graduacion.EsfOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.CilOi > 0 ? "+" + Graduacion.CilOi : Graduacion.CilOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EjeOi > 0 ? "+" + Graduacion.EjeOi : Graduacion.EjeOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.AddOi > 0 ? "+" + Graduacion.AddOi : Graduacion.AddOi}</td>
                  </tr>
                </tbody>
              </table>
              {/*Si existe Retinoscopia mostramos*/}
              {
                Graduacion.RetinosOd || Graduacion.RetinosOi ? (
                  <div>
                    <div style={{ textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Retinoscopia</strong>
                    </div>
                    <table style={{ width: '80%', margin: '0 auto', fontSize: '13px', fontWeight: '800', borderCollapse: 'collapse', marginTop: '2px' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center', border: '1px solid black', fontWeight: '800' }}>OD</th>
                          <th style={{ textAlign: 'center', border: '1px solid black', fontWeight: '800' }}>OI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center', border: '1px solid black' }}>{Graduacion.RetinosOd > 0 ? "+" + Graduacion.RetinosOd : Graduacion.RetinosOd}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black' }}>{Graduacion.RetinosOi > 0 ? "+" + Graduacion.RetinosOi : Graduacion.RetinosOi}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ): null
              }


              {/*Si existe Queratometria mostramos*/}
              {
                Graduacion.QueratoOd1 || Graduacion.QueratoOd2 ||  Graduacion.QueratoOd3 || Graduacion.QueratoOi1 || Graduacion.QueratoOi2 || Graduacion.QueratoOi3 ? (
                  <div>
                    <div style={{ textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Queratometría</strong>
                    </div>
                    <table style={{ width: '80%', margin: '2px auto', fontSize: '13px', fontWeight: '800', borderCollapse: 'collapse' }} className="tablaQuerato">
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: '800', textAlign: 'center', border: '1px solid black' }}>OD</td>
                          <td style={{ textAlign: 'right', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOd1 > 0 ? "+" + Graduacion.QueratoOd1 : Graduacion.QueratoOd1} /</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent'}}>{Graduacion.QueratoOd2 > 0 ? "+" + Graduacion.QueratoOd2 : Graduacion.QueratoOd2}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}><strong>X</strong></td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderLeftColor: 'transparent' }}>{Graduacion.QueratoOd3 > 0 ? "+" + Graduacion.QueratoOd3 : Graduacion.QueratoOd3}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: '800', textAlign: 'center', border: '1px solid black' }}>OI</td>
                          <td style={{ textAlign: 'right', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOi1 > 0 ? "+" + Graduacion.QueratoOi1 : Graduacion.QueratoOi1} /</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOi2 > 0 ? "+" + Graduacion.QueratoOi2 : Graduacion.QueratoOi2}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}><strong>X</strong></td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderLeftColor: 'transparent'  }}>{Graduacion.QueratoOi3 > 0 ? "+" + Graduacion.QueratoOi3 : Graduacion.QueratoOi3}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ): null
              }

              {/*Si hay Color*/}
              {
                Graduacion.color ? (
                  <div>
                    <div style={{ marginBottom: "2px", display: 'flex', width: '90%', alignItems: 'center', flexDirection: 'column', marginTop: '10px', marginLeft: '15px' }}>
                      <strong style={{ marginLeft: '0px', fontWeight: '800' }}>Dep. / Emp:</strong> {Graduacion.color || "N/A"}
                    </div>
                  </div>
                ):null
              }

               {/*Si hay lente de contacto*/}
               {
                Graduacion.lenteContactoDes ? (
                  <div>
                    <div style={{ marginBottom: "10px", display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Lente Contacto:</strong> {Graduacion.lenteContactoDes || "N/A"}
                    </div>
                  </div>
                ):null
              }
              
              <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'center', marginTop: '10px' }}>
                <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Observaciones:</strong>
              </div>
              <div style={{ marginBottom: "10px", width: '80%', textAlign: 'center', margin: 'auto' }}>
                {Graduacion.observaciones || "N/A"}
              </div>
            </div>   
          ))  
        ) : (
          <>
            <h4 style={{ textAlign: "center", color: "lightgray" }}>
              
            </h4>
          </>
        )}
         <div style={{ textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center', marginLeft: '15px'  }}>
           <strong>¡Gracias por su compra!</strong>
         </div>
       </div>
     
      ):(
        <>
        {
        NotaVenta.tipoTicketFis !== "Nota Simple" && (NotaVenta.saldoPendiente === 0 && NotaVenta.tipoTicketFis === "Comprobante") ? (
          <>
            <span style={{position: 'absolute', right: '0.2mm'}}>{NotaVenta.idFiscal.toString() + '-' + NotaVenta.sucursalNumber}</span>
          </>
        ) : null
      }
      <div style={{ textAlign: "center", marginBottom: "10px", fontWeight: 'bold', }}>
        
        {
          NotaVenta.tipoTicketFis === "Nota Simple"  ? (<strong>Nota de Venta</strong>) : (<strong>Comprobante</strong>)
        }
      </div>
      <div style={{display: 'flex', width: '100%', marginBottom: "10px"}}>
        <img
          src={logo}
          style={{width: "115px", margin: 'auto' }}
        />
      </div>
      <div style={{ marginBottom: "5px", textAlign:'center'}}>
        <strong>Fecha:</strong> {fechaFormateada}
      </div>

      {
        NotaVenta.tipoTicketFis !== "Nota Simple" ? (
          <>
            <div style={{ marginBottom: "5px", textAlign:'center', fontSize: '10px' }}>
              <strong>RFC:</strong> {NotaVenta.rfc}
            </div>
            <div style={{ marginBottom: "5px", textAlign:'center', fontSize: '10px' }}>
            {NotaVenta.razonSocial.toUpperCase()}
            </div>
          </>
        ) : null
      }
      
      <div style={{ marginBottom: "5px", textAlign:'center', padding: '0 0.5mm', fontSize: '10px' }}>
        {NotaVenta.direccionSucursal}
      </div>
      <div style={{ marginBottom: "5px", textAlign:'center'}}>
        <strong>Cliente:</strong> {NotaVenta.nombreCliente}
      </div>
      <div style={{ marginBottom: "5px", textAlign:'center'}}>
        <strong>Telefono Cliente:</strong> {NotaVenta.telefono}
      </div>
      <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Armazón:</strong> {NotaVenta.armazon}
      </div>
      <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Lente:</strong> {NotaVenta.lente}
      </div>
      <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Otro:</strong> {NotaVenta.otro}
      </div>
      <div style={{display: 'flex', width: '80%', justifyContent: 'space-between', marginLeft: '30px'}}>
        <div style={{ display: verPrecios, marginBottom: "2px", fontSize: '12px', fontWeight: 'bold', }}>
          <strong style={{textDecoration: 'underline'}}>Concepto</strong>
        </div>
        <div style={{ display: verPrecios, marginBottom: "2px", fontSize: '12px', fontWeight: 'bold', }}>
          <strong style={{textDecoration: 'underline'}}>Monto</strong>
        </div>     
      </div>
      <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Armazón:</strong> {NotaVenta.armazonImporte ?('$' + formatNumber(NotaVenta.armazonImporte)):null }
      </div>
      <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Lente:</strong> {NotaVenta.lenteImporte ?('$' + formatNumber(NotaVenta.lenteImporte)):null }
      </div>
      <div style={{display: verPrecios, marginBottom: "2px", width: '80%', justifyContent: 'space-between', borderBottom: '1px solid black', marginLeft: '30px'}}>
        <strong>Otro:</strong> {NotaVenta.otroImporte ?('$' + formatNumber(NotaVenta.otroImporte)):null }
      </div>
      <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Total:</strong> ${formatNumber(NotaVenta.total)}
      </div>
      {
       NotaVenta.tipoPago === "Anticipo" ? (
        <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
          <strong style={{marginLeft: '30px'}}>Anticipo:</strong> ${formatNumber(NotaVenta.anticipo) || 0}
        </div>
       ) : null 
      }
      <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
        <strong style={{marginLeft: '30px'}}>Saldo Pendiente:</strong> ${formatNumber(NotaVenta.saldoPendiente)}
      </div>
      {
        NotaVenta.anticipo !== 0 ? (
        <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between'}}>
          <strong style={{marginLeft: '30px'}}>Forma de Pago:</strong> <span>{capitalize(NotaVenta.formaPago)}</span>
        </div>
        ):null
      }
      
      {
        NotaVenta.fechaEntregaPrevista !== "" ? (
        <div style={{display: verPrecios, marginBottom: "2px", width: '90%', justifyContent: 'space-between', marginTop: "10px"}}>
          <strong style={{marginLeft: '30px'}}>Fecha de entrega:</strong> <span>{fechaFormateadaEntrega}</span>
        </div>
        ):null
      }
      <div style={{textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center' }}>
        <strong style={{marginLeft: '30px'}}>¡Gracias por su compra!</strong>
      </div>
      {Graduaciones.length > 0 ? (
          Graduaciones.map((Graduacion, index) => (
            <div style={{ marginTop: "10px", display: verGraduacion, marginBottom: '20px'}} key={index}>
              <div style={{ textAlign: "center", marginBottom: "10px", fontSize: '14px', fontWeight: 'bold', }}>
                <strong style={{textDecoration: 'underline'}}>Datos Laboratorio  {index + 1}</strong>
              </div>
              <div style={{textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center'}}>
                <strong style={{marginLeft: '30px', fontWeight: '800', fontSize: '14px' }}>Graduación</strong>
              </div>
              <table style={{ width: '80%', marginLeft: '25px', fontSize: '13px', fontWeight: '900', textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ textAlign: 'center', fontWeight: '800' }}>ESF</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>CIL</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>EJE</th>
                    <th style={{ textAlign: 'center', fontWeight: '800'  }}>ADD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: '800' }}>OD</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EsfOd > 0 ? "+" + Graduacion.EsfOd : Graduacion.EsfOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.CilOd > 0 ? "+" + Graduacion.CilOd : Graduacion.CilOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EjeOd > 0 ? "+" + Graduacion.EjeOd : Graduacion.EjeOd}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.AddOd > 0 ? "+" + Graduacion.AddOd : Graduacion.AddOd}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '800' }}>OI</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EsfOi > 0 ? "+" + Graduacion.EsfOi : Graduacion.EsfOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.CilOi > 0 ? "+" + Graduacion.CilOi : Graduacion.CilOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.EjeOi > 0 ? "+" + Graduacion.EjeOi : Graduacion.EjeOi}</td>
                    <td style={{border: '1px solid black'}}>{Graduacion.AddOi > 0 ? "+" + Graduacion.AddOi : Graduacion.AddOi}</td>
                  </tr>
                </tbody>
              </table>
              {/*Si existe Retinoscopia mostramos*/}
              {
                Graduacion.RetinosOd || Graduacion.RetinosOi ? (
                  <div>
                    <div style={{ textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Retinoscopia</strong>
                    </div>
                    <table style={{ width: '80%', margin: '0 auto', fontSize: '13px', fontWeight: '800', borderCollapse: 'collapse', marginTop: '2px' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center', border: '1px solid black', fontWeight: '800' }}>OD</th>
                          <th style={{ textAlign: 'center', border: '1px solid black', fontWeight: '800' }}>OI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center', border: '1px solid black' }}>{Graduacion.RetinosOd > 0 ? "+" + Graduacion.RetinosOd : Graduacion.RetinosOd}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black' }}>{Graduacion.RetinosOi > 0 ? "+" + Graduacion.RetinosOi : Graduacion.RetinosOi}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ): null
              }


              {/*Si existe Queratometria mostramos*/}
              {
                Graduacion.QueratoOd1 || Graduacion.QueratoOd2 ||  Graduacion.QueratoOd3 || Graduacion.QueratoOi1 || Graduacion.QueratoOi2 || Graduacion.QueratoOi3 ? (
                  <div>
                    <div style={{ textAlign: "center", marginTop: "10px", width: '90%', justifyContent: 'center' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Queratometría</strong>
                    </div>
                    <table style={{ width: '80%', margin: '2px auto', fontSize: '13px', fontWeight: '800', borderCollapse: 'collapse' }} className="tablaQuerato">
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: '800', textAlign: 'center', border: '1px solid black' }}>OD</td>
                          <td style={{ textAlign: 'right', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOd1 > 0 ? "+" + Graduacion.QueratoOd1 : Graduacion.QueratoOd1} /</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent'}}>{Graduacion.QueratoOd2 > 0 ? "+" + Graduacion.QueratoOd2 : Graduacion.QueratoOd2}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}><strong>X</strong></td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderLeftColor: 'transparent' }}>{Graduacion.QueratoOd3 > 0 ? "+" + Graduacion.QueratoOd3 : Graduacion.QueratoOd3}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: '800', textAlign: 'center', border: '1px solid black' }}>OI</td>
                          <td style={{ textAlign: 'right', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOi1 > 0 ? "+" + Graduacion.QueratoOi1 : Graduacion.QueratoOi1} /</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}>{Graduacion.QueratoOi2 > 0 ? "+" + Graduacion.QueratoOi2 : Graduacion.QueratoOi2}</td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderRightColor: 'transparent' }}><strong>X</strong></td>
                          <td style={{ textAlign: 'center', border: '1px solid black', borderLeftColor: 'transparent'  }}>{Graduacion.QueratoOi3 > 0 ? "+" + Graduacion.QueratoOi3 : Graduacion.QueratoOi3}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ): null
              }

              {/*Si hay Color*/}
              {
                Graduacion.color ? (
                  <div>
                    <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Dep. / Emp:</strong> {Graduacion.color || "N/A"}
                    </div>
                  </div>
                ):null
              }

               {/*Si hay lente de contacto*/}
               {
                Graduacion.lenteContactoDes ? (
                  <div>
                    <div style={{ marginBottom: "10px", display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                      <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Lente Contacto:</strong> {Graduacion.lenteContactoDes || "N/A"}
                    </div>
                  </div>
                ):null
              }
              
              <div style={{ marginBottom: "2px", display: 'flex', width: '90%', justifyContent: 'center', marginTop: '10px' }}>
                <strong style={{ marginLeft: '30px', fontWeight: '800' }}>Observaciones:</strong>
              </div>
              <div style={{ marginBottom: "10px", width: '80%', textAlign: 'center', margin: 'auto' }}>
                {Graduacion.observaciones || "N/A"}
              </div>
            </div>   
          ))  
        ) : (
          <>
            <h4 style={{ textAlign: "center", color: "lightgray" }}>
              
            </h4>
          </>
        )}
        {qrLink && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <QRCode value={qrLink} size={100} />
        </div>
      )}
        </>
      )}
      
    </div>
    
  );
};

export default NotaVentaView;
