import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Header from "../Header";
import "../../index.css";
import Swal from "sweetalert2";

// Función para obtener la fecha actual en México
const getMexicoCurrentDate = () => {
  const mexicoTimezoneOffset = -6; // UTC-6 para horario estándar de México
  const now = new Date();
  const mexicoDate = new Date(
    now.setHours(now.getHours() + mexicoTimezoneOffset)
  );
  return mexicoDate.toISOString().split("T")[0];
};

const DashboardCortesCaja = () => {
  const [date, setDate] = useState(getMexicoCurrentDate());
  const [cortes, setCortes] = useState([]);
  const [cortesSucursales, setCortesSucursales] = useState([]);
  const [cortesIndividuales, setCortesIndividuales] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchCortes = async () => {
      try {
        const response = await axios.get(`/cortesCaja/admin/${date}`);
        console.log(response.data);
        const cortesData = response.data.data || [];
        setCortes(cortesData);
        
        // Calcular el total general sumando todos los importes
        const totalAmount = cortesData.reduce(
          (sum, transaction) => sum + transaction.totalGeneral,
          0
        );
        setTotal(totalAmount);

        // Sucursales filtrado
        setCortesSucursales(
          cortesData.filter((corte) => corte.tipoUsuarioCreador === 1)
        );
  
        // Todos los demas sin los de sucursal y ordenados por dirección de sucursal
        setCortesIndividuales(
          cortesData
            .filter((corte) => corte.tipoUsuarioCreador !== 1)
            .sort((a, b) => a.direccionSucursal.localeCompare(b.direccionSucursal))
        );
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
  
    fetchCortes();
  }, [date]);

  return (
    <div>
      <Header />
      <div
        style={{
          margin: "50px 100px",
          display: "grid",
          justifyContent: "center",
        }}
      >
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          disabled={true}
          className="form-control"
          style={{
            width: "fit-content",
            textAlign: "center",
            fontWeight: "700",
          }}
        />
        <div style={{display: 'flex'}}>
          <div style={{margin:'0 30px'}}>
            <h4 style={{ marginTop: "50px" }}>
              <b>Cortes de Sucursales</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "800px", fontSize: '13px' }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sucursal</th>
                    <th scope="col">Encargado</th>
                    <th scope="col">Total Efectivo</th>
                    <th scope="col">Total Credito</th>
                    <th scope="col">Total Debito</th>
                    <th scope="col">Total Transferencia</th>
                    <th scope="col">Total General</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>
                      Fecha Registro
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cortesSucursales.map((transaction) => (
                    <tr key={transaction.idCorte}>
                      <td>{transaction.idCorte}</td>
                      <td style={{fontSize: '12px'}}>{transaction.direccionSucursal}</td>
                      <td>{transaction.nombreCreador}</td>
                      <td>{transaction.totalEfectivo.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalCredito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalDebito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalDebito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalTransferencia.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.fechaCorte.split("T")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> 
          
          <div>
            <h4 style={{ marginTop: "50px" }}>
              <b>Cortes Individuales</b>
            </h4>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <table
                className="table table-secondary"
                style={{ margin: "10px 0", width: "800px", fontSize: '13px' }}
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sucursal</th>
                    <th scope="col">Encargado</th>
                    <th scope="col">Total Efectivo</th>
                    <th scope="col">Total Credito</th>
                    <th scope="col">Total Debito</th>
                    <th scope="col">Total Transferencia</th>
                    <th scope="col">Total General</th>
                    <th scope="col" style={{ whiteSpace: "nowrap" }}>
                      Fecha Registro
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cortesIndividuales.map((transaction) => (
                    <tr key={transaction.idCorte}>
                      <td>{transaction.idCorte}</td>
                      <td style={{fontSize: '12px'}}>{transaction.direccionSucursal}</td>
                      <td>{transaction.nombreCreador}</td>
                      <td>{transaction.totalEfectivo.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalCredito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalDebito.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalTransferencia.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.totalGeneral.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{transaction.fechaCorte.split("T")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <h3>
          Total del Día Acumulado: <b>${total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
        </h3>
      </div>
    </div>
  );
};

export default DashboardCortesCaja;
