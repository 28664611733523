import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import Header from "../Header";
import "../../index.css";
import Loader from "../loader";
import QZTrayService from "../../api/qz-tray-service";

const ImpresionTickets = () => {
  const location = useLocation();
  const idPedido = location.state?.idPedido;
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  if (!idPedido) {
    setLoading(false);
    Swal.fire({
      title: "¡No hay ID de pedido!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Regresar",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/dashboard";
      }
    });
  }

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const body = { id: idPedido };
        const response = await axios.post("/pedidos/NotaVentaScreenshot", body);
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error al obtener los tickets:", error);
        Swal.fire("Error", "No se pudieron obtener los tickets", "error");
      }
    };
    fetchTickets();

    // Conectar con QZ Tray cuando el componente se monte
    QZTrayService.connect();

    return () => {
      // Desconectar QZ Tray cuando el componente se desmonte
      QZTrayService.disconnect();
    };
  }, []);

  const imprimirRecibo = async (imgUrl) => {
    if (!imgUrl) return;

    // Prepara los datos para ser enviados a QZ Tray
    const data = [
      {
        type: "image",
        format: "url", // Estás enviando una URL de imagen
        data: imgUrl, // URL de la imagen
      },
    ];

    // Llama al servicio de QZ Tray para imprimir el ticket
    try {
      const success = await QZTrayService.printData(data);

      if (success) {
        Swal.fire("Éxito", "Ticket enviado a impresión correctamente", "success");
      } else {
        Swal.fire("Error", "No se pudo imprimir, recargue la pagina e intente de nuevo, si el error persiste contacte a soporte.", "error");
      }
    } catch (error) {
      console.error("Error al imprimir el ticket:", error);
      Swal.fire(
        "Error",
        "Ocurrió un error inesperado al intentar imprimir el ticket",
        "error"
      );
    }
  };

  return (
    <div style={{ display: "grid" }}>
      <Header />
      <Loader isLoading={loading} />
      <h2
        style={{
          marginTop: "12%",
          textAlign: "center",
          marginBottom: "30px",
          fontWeight: "700",
        }}
      >
        Generación de Tickets
      </h2>
      <p
        style={{ textAlign: "center", marginBottom: "10px", fontSize: "20px" }}
      >
        Haz clic en cada botón para imprimir el ticket correspondiente. Luego,
        selecciona tu impresora térmica para completar el proceso.
      </p>
      <p
        style={{
          textAlign: "center",
          marginBottom: "50px",
          fontWeight: "800",
          fontSize: "11px",
          width: "750px",
          justifySelf: "center",
        }}
      >
        (Si después de 5 minutos los tickets no se muestran, por favor, recargue
        la página para reiniciar el proceso de creación. El servidor está
        configurado para eliminar las imágenes cada 5 minutos con el objetivo de
        optimizar el rendimiento.)
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => imprimirRecibo(tickets.notaVentaUrl)}
            disabled={!tickets.notaVentaUrl}
          >
            Imprimir Ticket Cliente
          </button>
          <button
            className="btn btn-primary"
            onClick={() => imprimirRecibo(tickets.notaAdminUrl)}
            disabled={!tickets.notaAdminUrl}
          >
            Imprimir Ticket Administrador
          </button>
          <button
            className="btn btn-primary"
            onClick={() => imprimirRecibo(tickets.notaLabUrl)}
            disabled={!tickets.notaLabUrl}
          >
            Imprimir Ticket Laboratorio
          </button>
        </div>
      </div>
      <button
        className="btn btn-secondary"
        style={{ margin: "80px auto" }}
        onClick={() => {
          QZTrayService.disconnect();
          window.location.href = "/dashboard/pedidos";
        }}
      >
        Regresar
      </button>
    </div>
  );
};

export default ImpresionTickets;
