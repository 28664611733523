const QZTrayService = {
    isDisconnecting: false,
    isConnecting: false, // Bandera para evitar múltiples conexiones

    connect: async function () {
        try {
            if (this.isConnecting || this.isDisconnecting) {
                //console.log("Esperando que termine la operación anterior...");
                return;
            }

            // Verifica si el WebSocket está activo
            if (!window.qz.websocket.isActive()) {
                this.isConnecting = true;

                // Intentar reconectar después de asegurarnos de que esté desconectado
                await this.ensureWebSocketClosed(); // Nueva función para asegurar el cierre
                //console.log("Iniciando conexión a QZ Tray...");

                await window.qz.websocket.connect(); // Intentar conectar
                //console.log("Conexión exitosa con QZ Tray");
            } else {
                //console.log("Ya existe una conexión activa con QZ Tray");
            }
        } catch (error) {
            console.error("Error conectando a QZ Tray: ", error);
        } finally {
            this.isConnecting = false; // Marcar como finalizada la conexión
        }
    },

    disconnect: async function () {
        if (window.qz.websocket.isActive()) {
            try {
                this.isDisconnecting = true;
                await window.qz.websocket.disconnect();
                //console.log("Conexión con QZ Tray cerrada");
            } catch (error) {
                console.error("Error al desconectar de QZ Tray: ", error);
            } finally {
                this.isDisconnecting = false;
            }
        } else {
            //console.log("No hay conexión activa con QZ Tray para desconectar");
        }
    },

    // Nueva función para asegurarnos de que el WebSocket esté realmente cerrado
    ensureWebSocketClosed: async function () {
        if (window.qz.websocket.isActive()) {
            //console.log("Desconectando WebSocket antes de reconectar...");
            await window.qz.websocket.disconnect(); // Aseguramos que se desconecta

            // Retardo de 500 ms para asegurarnos de que el WebSocket se ha cerrado
            await new Promise(resolve => setTimeout(resolve, 500));
        }
    },

    getAvailablePrinter: async function () {
        let defaultPrinter = localStorage.getItem("defaultPrinter");

        if (defaultPrinter) {
            //console.log(`Usando impresora por defecto desde localStorage: ${defaultPrinter}`);
            return defaultPrinter;
        }

        try {
            const printers = await window.qz.printers.find();
            const preferredPrinters = ["BIXOLON SRP-330III", "POS-80C", "POS80 Printer"];

            const availablePrinter = preferredPrinters.find(printer => printers.includes(printer));

            if (availablePrinter) {
                localStorage.setItem("defaultPrinter", availablePrinter); // Guarda la impresora seleccionada en localStorage
                //console.log(`Impresora seleccionada: ${availablePrinter}`);
                return availablePrinter;
            } else {
                console.error("No se encontró ninguna impresora preferida conectada");
                return null;
            }
        } catch (error) {
            console.error("Error al obtener impresoras disponibles: ", error);
            return null;
        }
    },

    printData: async function (data) {
        const printer = await this.getAvailablePrinter(); // Busca la impresora disponible y conectada

        if (printer) {
            const config = window.qz.configs.create(printer);
            try {
                await window.qz.print(config, data);
                //console.log(`Impresión exitosa en ${printer}`);
                return true;
            } catch (error) {
                console.error(`Error al imprimir en ${printer}: `, error);
                return false;
            }
        } else {
            console.error("No se pudo imprimir. No hay impresoras disponibles.");
            return false;
        }
    }
};

export default QZTrayService;
