import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import "../../index.css";
import Loader from "../loader";

const HistoriaClinica = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cliente = location.state?.cliente;
  const sucursales = location.state?.sucursales;
  if (!cliente) {
    navigate("/dashboard/clientes");
  }


  const [loading, setLoading] = useState(false);
  const [dificultadLejos, setDificultadLejos] = useState("No");
  const [dificultadCerca, setDificultadCerca] = useState("No");
  const [dolorCabeza, setDolorCabeza] = useState("No");
  const [irritacion, setIrritacion] = useState("No");
  const [diabetes, setDiabetes] = useState("No");
  const [hipertension, setHipertension] = useState("No");

  // Estado para la tabla de prescripción
  const [esfOd, setEsfOd] = useState("");
  const [esfOi, setEsfOi] = useState("");
  const [cilOd, setCilOd] = useState("");
  const [cilOi, setCilOi] = useState("");
  const [ejeOd, setEjeOd] = useState("");
  const [ejeOi, setEjeOi] = useState("");
  const [addOd, setAddOd] = useState("");
  const [addOi, setAddOi] = useState("");

  const [esfOdTot, setEsfOdTot] = useState("");
  const [esfOiTot, setEsfOiTot] = useState("");
  const [cilOdTot, setCilOdTot] = useState("");
  const [cilOiTot, setCilOiTot] = useState("");
  const [ejeOdTot, setEjeOdTot] = useState("");
  const [ejeOiTot, setEjeOiTot] = useState("");
  const [addOdTot, setAddOdTot] = useState("");
  const [addOiTot, setAddOiTot] = useState("");

  const [esfOdPre, setEsfOdPre] = useState("");
  const [esfOiPre, setEsfOiPre] = useState("");
  const [cilOdPre, setCilOdPre] = useState("");
  const [cilOiPre, setCilOiPre] = useState("");
  const [ejeOdPre, setEjeOdPre] = useState("");
  const [ejeOiPre, setEjeOiPre] = useState("");
  const [addOdPre, setAddOdPre] = useState("");
  const [addOiPre, setAddOiPre] = useState("");

  const [avOd, setAvOd] = useState("");
  const [avOi, setAvOi] = useState("");
  const [cvOd, setCvOd] = useState("");
  const [cvOi, setCvOi] = useState("");
  const [retinosOd, setRetinosOd] = useState("");
  const [retinosOi, setRetinosOi] = useState("");
  const [queratoOd1, setQueratoOd1] = useState("");
  const [queratoOd2, setQueratoOd2] = useState("");
  const [queratoOd3, setQueratoOd3] = useState("");
  const [queratoOi1, setQueratoOi1] = useState("");
  const [queratoOi2, setQueratoOi2] = useState("");
  const [queratoOi3, setQueratoOi3] = useState("");

  const [armazon, setArmazon] = useState("");
  const [cristal, setCristal] = useState("");
  const [tipoArmazon, setTipoArmazon] = useState("");
  const [tipo, setTipo] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [color, setColor] = useState("");
  const [lenteContactoDes, setLenteContactoDes] = useState("");
  const [diData, setDiData] = useState("");
  const [aoData, setAoData] = useState("");
  const [di2Data, setDi2Data] = useState("");
  const [ao2Data, setAo2Data] = useState("");
  const [total, setTotal] = useState("");
  const [anticipo, setAnticipo] = useState("");
  const [saldo, setSaldo] = useState("");
  const [optometrista, setOptometrista] = useState("FLAVIANO PELAYO SALAZAR");
  const [cedula, setCedula] = useState("");
  const [lastVisita, setLastVisita] = useState([]);
  const [lastVisitaActual, setLastVisitaActual] = useState([]);
  

  //Cliente
  const [edadCliente, setEdadCliente] = useState("");
  const [telefonoCliente, setTelefonoCliente] = useState("");

  useEffect(() => {
    
    const fetchUltimaVisita = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/historiaClinica/ultimaVisita/${cliente.id}`
        );
        setLastVisita(response.data.historia);
        setEsfOd(response.data.historia.EsfOd);
        setEsfOi(response.data.historia.EsfOi);
        setCilOd(response.data.historia.CilOd);
        setCilOi(response.data.historia.CilOi);
        setEjeOd(response.data.historia.EjeOd);
        setEjeOi(response.data.historia.EjeOi);
        setAddOd(response.data.historia.AddOd);
        setAddOi(response.data.historia.AddOi);
        setAvOd(response.data.historia.AvOd);
        setAvOi(response.data.historia.AvOi);
        setCvOd(response.data.historia.CvOd);
        setCvOi(response.data.historia.CvOi);
        setRetinosOd(response.data.historia.RetinosOd);
        setRetinosOi(response.data.historia.RetinosOi);
        setQueratoOd1(response.data.historia.QueratoOd1);
        setQueratoOd2(response.data.historia.QueratoOd2);
        setQueratoOd3(response.data.historia.QueratoOd3);
        setQueratoOi1(response.data.historia.QueratoOi1);
        setQueratoOi2(response.data.historia.QueratoOi2);
        setQueratoOi3(response.data.historia.QueratoOi3);
        setEdadCliente(response.data.historia.edad);
        setTelefonoCliente(response.data.historia.telefono);
        setObservaciones(response.data.historia.observaciones);
        setDificultadLejos(response.data.historia.DificultadLejos);
        setDificultadCerca(response.data.historia.DificultadCerca);
        setIrritacion(response.data.historia.Irritacion);
        setDolorCabeza(response.data.historia.DolorCabeza);
        setDiabetes(response.data.historia.Diabetes);
        setHipertension(response.data.historia.Hipertension);
        setDiData(response.data.historia.diData);
        setAoData(response.data.historia.aoData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la última historia:", error);
        setLoading(false);
        Swal.fire({
          icon: "info",
          title: "La visita anterior no está registrada",
          text: "Favor de ingresar otra mas para tener registros suficientes u obtenerlos de otra fuente de información.",
        });
      }
    };


    const fetchUltimaVisitaActual = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/historiaClinica/ultimaVisitaActual/${cliente.id}`
        );
        setLastVisitaActual(response.data.historia);
        setEsfOdPre(response.data.historia.EsfOd);
        setEsfOiPre(response.data.historia.EsfOi);
        setCilOdPre(response.data.historia.CilOd);
        setCilOiPre(response.data.historia.CilOi);
        setEjeOdPre(response.data.historia.EjeOd);
        setEjeOiPre(response.data.historia.EjeOi);
        setAddOdPre(response.data.historia.AddOd);
        setAddOiPre(response.data.historia.AddOi);
        setAddOiPre(response.data.historia.AddOi);
        setDi2Data(response.data.historia.diData);
        setAo2Data(response.data.historia.aoData);
        setEdadCliente(response.data.historia.edad);
        setTelefonoCliente(response.data.historia.telefono);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la última historia:", error);
        setLoading(false);
        Swal.fire({
          icon: "warning",
          title: "Error al obtener la última visita",
          text: "Hubo un problema al cargar los detalles de la última visita. Por favor, inténtalo de nuevo más tarde.",
        });
      }
    };

    fetchUltimaVisita();
    fetchUltimaVisitaActual();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const branchId = parseInt(localStorage.getItem("branchId"), 10);
    const sucursal = sucursales.find((suc) => suc.id === branchId);
    
    const requestData = {
      idCliente: cliente.id,
      nombreCliente: cliente.nombreCompleto,
      idSucursal: localStorage.getItem("branchId"),
      nombreSucursal: sucursal.direccion || "",
      dificultadLejos,
      dificultadCerca,
      dolorCabeza,
      irritacion,
      diabetes,
      hipertension,
      esfOd,
      esfOi,
      cilOd,
      cilOi,
      ejeOd,
      ejeOi,
      addOd,
      addOi,
      esfOdTot,
      esfOiTot,
      cilOdTot,
      cilOiTot,
      ejeOdTot,
      ejeOiTot,
      addOdTot,
      addOiTot,
      esfOdPre,
      esfOiPre,
      cilOdPre,
      cilOiPre,
      ejeOdPre,
      ejeOiPre,
      addOdPre,
      addOiPre,
      avOd,
      avOi,
      cvOd,
      cvOi,
      retinosOd,
      retinosOi,
      queratoOd1,
      queratoOd2,
      queratoOd3,
      queratoOi1,
      queratoOi2,
      queratoOi3,
      armazon,
      cristal,
      tipoArmazon,
      tipo,
      observaciones,
      color,
      lenteContactoDes,
      diData,
      aoData,
      ao2Data,
      di2Data,
      total,
      anticipo,
      saldo,
      optometrista,
      cedula,
      createDate: new Date().toISOString(), // Fecha actual en formato ISO
      edadCliente,
      telefonoCliente
    };

    try {
      const response = await axios.put("/historiaClinica/crear", requestData);
      if (response.data.success) {
        Swal.fire({
          title: "Historia clinica creada correctamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        }).then(() => {
          // Redireccionar a /dashboard/clientes después de crear la visita
          navigate("/dashboard/clientes");
        });
      } else {
        Swal.fire("Error", "No se pudo crear la historia", "error");
      }
    } catch (error) {
      console.error("Error al crear la visita:", error);
      Swal.fire("Error", "Hubo un problema al crear la visita", "error");
    }
  };

  function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }

  const inputTablaOptometria = {
    border: "1px solid black",
    borderRadius: "5px",
    borderColor: "gray",
    width: "65px",
  };

  return (
    <div
      style={{
        marginTop: "50px",
        color: "black",
        padding: "0 50px",
        display: "grid",
      }}
    >
      <Header></Header>
      <Loader isLoading={loading} />
      <p
        style={{
          fontSize: "29px",
          textAlign: "center",
          fontWeight: "700",
          textDecoration: "underline",
          position: "absolute",
          width: "400px",
          border: "1px solid black",
          borderRadius: "20px",
        }}
      >
        Paciente: <br />
        <span style={{ fontWeight: "500" }}>{cliente?.nombreCompleto}</span>
      </p>
      <h3
        style={{ fontWeight: "700", textAlign: "center", paddingTop: "10px" }}
      >
        Nueva Historia Clinica: <span style={{fontWeight: '400'}}>Prescripción de Optometría</span>
      </h3>
      <form
        onSubmit={handleSubmit}
        style={{ display: "grid", justifyItems: "center", paddingTop: "20px" }}
      >
        <div style={{display: 'flex'}}>
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Optometrista
            </div>
            <input
              type="text"
              value={optometrista}
              onChange={(e) => setOptometrista(e.target.value)}
              placeholder="Ingrese nombre"
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "7px",
                textAlign: "center",
                width: "250px",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px", marginLeft: '20px'}}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Cedula Prof.
            </div>
            <input
              type="text"
              value={cedula}
              onChange={(e) => setCedula(e.target.value)}
              placeholder="Ingrese cedula"
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "7px",
                textAlign: "center",
                width: "170px",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px", marginLeft: '20px', borderLeft: '1px solid black', paddingLeft: '20px'}}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Edad del Paciente
            </div>
            <input
              type="text"
              value={edadCliente}
              onChange={(e) => setEdadCliente(e.target.value)}
              placeholder="Ingrese edad"
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "7px",
                textAlign: "center",
                width: "200px",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px", marginLeft: '20px'}}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Teléfono del Paciente
            </div>
            <input
              type="number"
              value={telefonoCliente}
              onChange={(e) => setTelefonoCliente(e.target.value)}
              placeholder="Ingrese telefono"
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "7px",
                textAlign: "center",
                width: "200px",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              marginRight: "20px",
            }}
          >
            <div>
              <span style={{ fontWeight: "700" }}>
                Refiere dificultad a lo lejos
              </span>
              <select
                className="form-control form-select"
                value={dificultadLejos}
                onChange={(e) => setDificultadLejos(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div>
              <span style={{ fontWeight: "700" }}>
                Refiere dificultad a lo cerca
              </span>
              <select
                className="form-control form-select"
                value={dificultadCerca}
                onChange={(e) => setDificultadCerca(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div>
              <span style={{ fontWeight: "700" }}>Dolor de cabeza</span>
              <select
                className="form-control form-select"
                value={dolorCabeza}
                onChange={(e) => setDolorCabeza(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div>
              <span style={{ fontWeight: "700" }}>Irritación</span>
              <select
                className="form-control form-select"
                value={irritacion}
                onChange={(e) => setIrritacion(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div>
              <span style={{ fontWeight: "700" }}>Diabetes</span>
              <select
                className="form-control form-select"
                value={diabetes}
                onChange={(e) => setDiabetes(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>

            <div>
              <span style={{ fontWeight: "700" }}>Hipertensión</span>
              <select
                className="form-control form-select"
                value={hipertension}
                onChange={(e) => setHipertension(e.target.value)}
                required
                style={{ marginBottom: "15px", width: "85px" }}
              >
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>
          </div>

          <div
            style={{
              marginLeft: "20px",
              display: "grid",
              justifyItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "700",
                fontSize: "17px",
                textAlign: "center",
              }}
            >
              Observaciones
            </div>
            <textarea
              type="text"
              value={observaciones}
              onChange={(e) => setObservaciones(e.target.value)}
              placeholder="Ingrese sus observaciones"
              style={{
                border: "1px solid black",
                padding: "5px",
                borderRadius: "7px",
                width: "300px",
                textAlign: "center",
                justifySelf: "center",
                height: '120px'
              }}
            />
          </div>
        </div>

        <div
          style={{
            margin: "30px 0 0 0px",
            display: "grid",
            justifyItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "center",
              }}
            ></div>
            <div style={{ display: "grid" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  padding: "15px 0",
                }}
              >
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    Graduacion anterior
                  </p>
                  <div style={{ display: "flex" }}>
                    <table>
                      <thead>
                        <tr>
                          <th> &nbsp;</th>
                          <th style={{ textAlign: "center" }}>ESF</th>
                          <th style={{ textAlign: "center" }}>CIL</th>
                          <th style={{ textAlign: "center" }}>EJE</th>
                          <th style={{ textAlign: "center" }}>ADD</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "center" }}>OD</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={esfOd}
                              onChange={(e) => setEsfOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={cilOd}
                              onChange={(e) => setCilOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={ejeOd}
                              onChange={(e) => setEjeOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={addOd}
                              onChange={(e) => setAddOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "center" }}>OI</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={esfOi}
                              onChange={(e) => setEsfOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={cilOi}
                              onChange={(e) => setCilOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={ejeOi}
                              onChange={(e) => setEjeOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={addOi}
                              onChange={(e) => setAddOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table style={{ margin: "0px 0 0 50px" }}>
                      <thead>
                        <tr>
                          <th> &nbsp;&nbsp;</th>
                          <th style={{ textAlign: "center" }}>A/V</th>
                          <th></th>
                          <th style={{ textAlign: "center" }}>C/V</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "right" }}>OD 20/</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={avOd}
                              onChange={(e) => setAvOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;OD 20/</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={cvOd}
                              onChange={(e) => setCvOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "right" }}>OI 20/</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={avOi}
                              onChange={(e) => setAvOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td style={{ textAlign: "right" }}>
                            &nbsp;&nbsp;&nbsp;OI 20/
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={cvOi}
                              onChange={(e) => setCvOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      display: "grid",
                      marginLeft: "50px",
                    }}
                  >
                    <h5 style={{ fontWeight: "700", alignSelf: "end" }}>
                      Retinoscopia
                    </h5>
                    <table style={{ justifySelf: "center" }}>
                      <thead>
                        <tr>
                          <th>OD</th>
                          <th>OI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={retinosOd}
                              onChange={(e) => setRetinosOd(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={retinosOi}
                              onChange={(e) => setRetinosOi(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                      display: "grid",
                      marginLeft: "80px",
                    }}
                  >
                    <h5 style={{ fontWeight: "700", alignSelf: "end" }}>
                      Queratometría
                    </h5>
                    <table
                      style={{ margin: "0 0 0 0px", justifySelf: "center" }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            OD / &nbsp;
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={queratoOd1}
                              onChange={(e) => setQueratoOd1(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={queratoOd2}
                              onChange={(e) => setQueratoOd2(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            &nbsp;X&nbsp;
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={queratoOd3}
                              onChange={(e) => setQueratoOd3(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            OI / &nbsp;&nbsp;
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={queratoOi1}
                              onChange={(e) => setQueratoOi1(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              min="-1000"
                              max="1000"
                              value={queratoOi2}
                              onChange={(e) => setQueratoOi2(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            &nbsp;X&nbsp;
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={queratoOi3}
                              onChange={(e) => setQueratoOi3(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid black",
                  paddingBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifySelf: "center",
                    marginTop: "0px",
                  }}
                >
                  <div style={{ display: "grid", justifyItems: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        textAlign: "center",
                        marginTop: "30px",
                      }}
                    >
                      Graduación Total
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th> &nbsp;</th>
                          <th style={{ textAlign: "center" }}>ESF</th>
                          <th style={{ textAlign: "center" }}>CIL</th>
                          <th style={{ textAlign: "center" }}>EJE</th>
                          <th style={{ textAlign: "center" }}>ADD</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "center" }}>OD</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={esfOdTot}
                              onChange={(e) => setEsfOdTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={cilOdTot}
                              onChange={(e) => setCilOdTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={ejeOdTot}
                              onChange={(e) => setEjeOdTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={addOdTot}
                              onChange={(e) => setAddOdTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "center" }}>OI</td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={esfOiTot}
                              onChange={(e) => setEsfOiTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={cilOiTot}
                              onChange={(e) => setCilOiTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={ejeOiTot}
                              onChange={(e) => setEjeOiTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              step="0.25"
                              max="1000"
                              value={addOiTot}
                              onChange={(e) => setAddOiTot(e.target.value)}
                              style={inputTablaOptometria}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ display: "grid", marginLeft: "30px" }}>
                    <div style={{ marginRight: "10px" }}>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "center",
                          marginTop: "25px",
                        }}
                      >
                        DI
                      </div>
                      <input
                        type="text"
                        value={diData}
                        onChange={(e) => setDiData(e.target.value)}
                        placeholder="Ingrese DI"
                        style={{
                          border: "1px solid black",
                          padding: "5px",
                          borderRadius: "7px",
                          width: "130px",
                          textAlign: "center",
                          justifySelf: "center",
                        }}
                      />
                    </div>

                    <div style={{ marginLeft: "0px" }}>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        AO
                      </div>
                      <input
                        type="text"
                        value={aoData}
                        onChange={(e) => setAoData(e.target.value)}
                        placeholder="Ingrese AO"
                        style={{
                          border: "1px solid black",
                          padding: "5px",
                          borderRadius: "7px",
                          width: "130px",
                          textAlign: "center",
                          justifySelf: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "5px",
                    width: "700px",
                    marginLeft: "20px",
                  }}
                >
                  <div style={{ flex: "1 1 200px", textAlign: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Armazon
                    </div>
                    <textarea
                      type="text"
                      value={armazon}
                      onChange={(e) => setArmazon(e.target.value)}
                      placeholder="Ingrese armazon"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        borderRadius: "7px",
                        width: "100%",
                        textAlign: "center",
                        height: "38px",
                      }}
                    />
                  </div>

                  <div style={{ flex: "1 1 200px", textAlign: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Cristal
                    </div>
                    <textarea
                      type="text"
                      value={cristal}
                      onChange={(e) => setCristal(e.target.value)}
                      placeholder="Ingrese valor"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        borderRadius: "7px",
                        width: "100%",
                        textAlign: "center",
                        height: "38px",
                      }}
                    />
                  </div>

                  <div style={{ flex: "1 1 200px", textAlign: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Tipo de lente
                    </div>
                    <select
                      className="form-control form-select"
                      id="diabetes"
                      value={tipoArmazon}
                      onChange={(e) => setTipoArmazon(e.target.value)}
                      required
                      style={{
                        marginBottom: "15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <option value="Solo cerca">Solo cerca</option>
                      <option value="Monofocal">Monofocal</option>
                      <option value="Progresivo">Progresivo</option>
                      <option value="Bifocal">Bifocal</option>
                      <option value="Lente de contacto">
                        Lente de contacto
                      </option>
                    </select>
                  </div>

                  <div style={{ flex: "1 1 200px", textAlign: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Tratamiento
                    </div>
                    <textarea
                      type="text"
                      value={tipo}
                      onChange={(e) => setTipo(e.target.value)}
                      placeholder="Ingrese tipo"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        borderRadius: "7px",
                        width: "100%",
                        textAlign: "center",
                        height: "38px",
                      }}
                    />
                  </div>

                  <div style={{ flex: "1 1 200px", textAlign: "center" }}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Lente de contacto (Descripción)
                    </div>
                    <textarea
                      type="text"
                      value={lenteContactoDes}
                      onChange={(e) => setLenteContactoDes(e.target.value)}
                      placeholder="Ingrese descripción"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        borderRadius: "7px",
                        width: "100%",
                        textAlign: "center",
                        height: "38px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              >
                Graduación Prescrita
              </div>
              <table style={{ justifySelf: "center" }}>
                <thead>
                  <tr>
                    <th> &nbsp;</th>
                    <th style={{ textAlign: "center" }}>ESF</th>
                    <th style={{ textAlign: "center" }}>CIL</th>
                    <th style={{ textAlign: "center" }}>EJE</th>
                    <th style={{ textAlign: "center" }}>ADD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>OD</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={esfOdPre}
                        onChange={(e) => setEsfOdPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cilOdPre}
                        onChange={(e) => setCilOdPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={ejeOdPre}
                        onChange={(e) => setEjeOdPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={addOdPre}
                        onChange={(e) => setAddOdPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>OI</td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={esfOiPre}
                        onChange={(e) => setEsfOiPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={cilOiPre}
                        onChange={(e) => setCilOiPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={ejeOiPre}
                        onChange={(e) => setEjeOiPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.25"
                        min="-1000"
                        max="1000"
                        value={addOiPre}
                        onChange={(e) => setAddOiPre(e.target.value)}
                        style={inputTablaOptometria}
                        placeholder="0.00"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                display: "grid",
                flexDirection: "row",
                justifySelf: "center",
                marginLeft: "30px",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    textAlign: "center",
                    marginTop: "25px",
                  }}
                >
                  DI
                </div>
                <input
                  type="text"
                  value={di2Data}
                  onChange={(e) => setDi2Data(e.target.value)}
                  placeholder="Ingrese DI"
                  style={{
                    border: "1px solid black",
                    padding: "5px",
                    borderRadius: "7px",
                    width: "130px",
                    textAlign: "center",
                    justifySelf: "center",
                  }}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  AO
                </div>
                <input
                  type="text"
                  value={ao2Data}
                  onChange={(e) => setAo2Data(e.target.value)}
                  placeholder="Ingrese AO"
                  style={{
                    border: "1px solid black",
                    padding: "5px",
                    borderRadius: "7px",
                    width: "130px",
                    textAlign: "center",
                    justifySelf: "center",
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                Total
              </div>
              <input
                type="number"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                placeholder="Ingrese total"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "130px",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              />
            </div>

            <div style={{ marginRight: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                Anticipo
              </div>
              <input
                type="number"
                value={anticipo}
                onChange={(e) => setAnticipo(e.target.value)}
                placeholder="Ingrese anticipo"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "150px",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              />
            </div>

            <div style={{ marginRight: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "25px",
                }}
              >
                Saldo
              </div>
              <input
                type="number"
                value={saldo}
                onChange={(e) => setSaldo(e.target.value)}
                placeholder="Ingrese saldo"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "7px",
                  width: "130px",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            style={{
              margin: "20px 0 20px 0px",
              justifySelf: "center",
            }}
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default HistoriaClinica;
