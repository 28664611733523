import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "../../api/axiosConfig";
import "../../index.css";



const ValidateEmailView = () => {
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); 

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(`/confirmar-cita?token=${token}`);
          if (response.data.success) {
            const appointmentDetails = response.data.data; 
            setMessage('¡Tu cita se ha validado con éxito! Puedes cerrar esta ventana.');
            setIsSuccess(true);

            try {
              await axios.post('/whatsapp/ConfirmacionCita', {
                Fecha: appointmentDetails.fecha,
                Hora: appointmentDetails.hora,
                nombreOptica: 'Opticos Pelayo', 
                nombreCliente: appointmentDetails.clienteNombre,
                phoneNumber: appointmentDetails.telefonoCliente
              });
            } catch (error) {
              console.error('Error al enviar la confirmación de WhatsApp:', error);
            }

          } else {
            setMessage('La cita no pudo ser confirmada. Puede que ya haya sido confirmada.');
            setIsSuccess(false);
          }
        } catch (error) {
          console.error('Error al realizar la solicitud:', error);
          setMessage('La cita no pudo ser confirmada. Puede que ya haya sido confirmada.');
          setIsSuccess(false);
        }
      } else {
        setMessage('Parámetro de consulta no encontrado');
        setIsSuccess(false);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div className="validate-email-view-container">
      <div className={`validate-email-view-message-box ${isSuccess ? 'validate-email-view-success' : 'validate-email-view-error'}`}>
        <h1>{message}</h1>
      </div>
    </div>
  );
};


export default ValidateEmailView;
