import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import Header from "../Header";
import QZTrayService from "../../api/qz-tray-service";
import Loader from "../loader";

const UltimaVisitaHistorial = () => {
  const location = useLocation();
  const [ultimaVisita, setUltimaVisita] = useState(location.state?.visita);
  const [loading, setLoading] = useState(true);
  const [imagenes, setImagenes] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messagePhotos, setMessagePhotos] = useState("Cargando imágenes...");

  const navigate = useNavigate();

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchUltimaVisita = async () => {
      try {
        const fotos = await axios.get(
          `/visitas/ultimaFotos/${ultimaVisita.IdVisita}`
        );

        if (fotos.data.length > 0) {
          const imagenesUrls = fotos.data.map((fotoObj) => {
            const fotoData = fotoObj.foto.data;
            const base64 = btoa(
              new Uint8Array(fotoData).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            return `data:image/jpeg;base64,${base64}`;
          });
          setImagenes(imagenesUrls);
        } else {
          setMessagePhotos(
            "No se encontraron fotos en la visita para este cliente"
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la última visita:", error);
        Swal.fire({
          icon: "warning",
          title: "Error al obtener la última visita",
          text: "Hubo un problema al cargar los detalles de la última visita. Por favor, inténtalo de nuevo más tarde.",
        });
        setLoading(false);
      }
    };

    fetchUltimaVisita();

    return () => {
      // Desconectar QZ Tray cuando el componente se desmonte
      QZTrayService.disconnect();
    };
  }, []);

  if (!ultimaVisita) {
    return <div>No se encontró la última visita.</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const handleRegresar = () => {
    navigate(-1);
  };

  const reimprimirTicketLaboratorio = async () => {
    try {
      // Esperar a que se complete la conexión con QZ Tray
      await QZTrayService.connect();
  
      const body = {
        idCliente: ultimaVisita.IdCliente,
        FechaCreacion: ultimaVisita.CreateDate,
      };
      setLoading(true);
      const response = await axios.post(
        "/pedidos/NotaVentaScreenshotLaboratorio",
        body
      );
      const tickets = response.data;
      setLoading(false);
  
      // Prepara los datos para ser enviados a QZ Tray
      const data = [
        {
          type: "image",
          format: "url", // Estás enviando una URL de imagen
          data: tickets.notaLabUrl, // URL de la imagen
        },
      ];

      console.log(tickets)
  
      // Llama al servicio de QZ Tray para imprimir el ticket
      const success = await QZTrayService.printData(data);
  
      if (success) {
        Swal.fire(
          "Éxito",
          "Ticket enviado a impresión correctamente",
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          "No se pudo imprimir, recargue la pagina e intente de nuevo, si el error persiste contacte a soporte.",
          "error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error al obtener los tickets o al imprimir:", error);
      Swal.fire("Error", "Ocurrió un error inesperado, por favor inténtalo de nuevo.", "error");
    }
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Loader isLoading={loading} />
      <button
        className="btn btn-sm btn-primary"
        onClick={handleRegresar}
        style={{
          width: "200px",
          alignSelf: "end",
          marginRight: "5vw",
          marginBottom: "10px",
          marginTop: "40px",
        }}
      >
        Regresar
      </button>

      <button
        className="btn btn-sm btn-success"
        onClick={reimprimirTicketLaboratorio}
        style={{
          width: "200px",
          alignSelf: "end",
          marginRight: "5vw",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        Reimprimir Ticket
      </button>

      <div className="container">
        <div className="card bg-light text-black mb-3">
          <div className="card-body titlePedidoVista">
            <h2 className="mb-4" style={{ color: "black" }}>
              Detalles de la Visita
            </h2>
          </div>
        </div>
        <div
          className="card-container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="card bg-light text-black mb-3"
            style={{ flex: "1 1 70%" }}
          >
            <div className="card-body">
              <h5 className="card-title">Paciente</h5>
              <p className="card-text">{ultimaVisita.NombreCliente}</p>
            </div>
          </div>
          <div
            className="card bg-light text-black mb-3"
            style={{ flex: "0 1 30%" }}
          >
            <div className="card-body">
              <h5 className="card-title">Fecha de Creación</h5>
              <p className="card-text">{formatDate(ultimaVisita.CreateDate)}</p>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{ borderBottom: "2px solid #ccc", margin: "20px 0" }}
          ></div>
          <div
            className="card-container"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "stretch",
              gap: "10px",
            }}
          >
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Dificultad Lejos</h5>
                <p className="card-text">{ultimaVisita.DificultadLejos}</p>
              </div>
            </div>
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Dificultad Cerca</h5>
                <p className="card-text">{ultimaVisita.DificultadCerca}</p>
              </div>
            </div>
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Dolor de Cabeza</h5>
                <p className="card-text">{ultimaVisita.DolorCabeza}</p>
              </div>
            </div>
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Irritación</h5>
                <p className="card-text">{ultimaVisita.Irritacion}</p>
              </div>
            </div>
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Hipertensión</h5>
                <p className="card-text">{ultimaVisita.Hipertension}</p>
              </div>
            </div>
            <div
              className="card bg-light text-black mb-3"
              style={{ flex: "1 1 200px" }}
            >
              <div className="card-body">
                <h5 className="card-title">Diabetes</h5>
                <p className="card-text">{ultimaVisita.Diabetes}</p>
              </div>
            </div>
          </div>
          <div
            className="card-container"
            style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
          >
            {ultimaVisita.EsfOd ||
            ultimaVisita.EsfOi ||
            ultimaVisita.CilOd ||
            ultimaVisita.CilOi ||
            ultimaVisita.EjeOd ||
            ultimaVisita.EjeOi ||
            ultimaVisita.AddOd ||
            ultimaVisita.AddOi ? (
              <div className="card bg-light text-black mb-3">
                <div className="card-body">
                  <h5 className="card-title">Prescripción</h5>
                  <table
                    style={{
                      width: "50%",
                      margin: "2px auto",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderCollapse: "collapse",
                    }}
                    className="tablaQuerato"
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "0px solid black",
                          }}
                        ></td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          Esf:
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          Cil:
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          Eje:
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          Add:
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OD
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.EsfOd}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.CilOd}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.EjeOd}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.AddOd}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OI
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.EsfOi}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.CilOi}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.EjeOi}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.AddOi}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {ultimaVisita.AvOd ||
            ultimaVisita.AvOi ||
            ultimaVisita.CvOd ||
            ultimaVisita.CvOi ? (
              <div className="card bg-light text-black mb-3">
                <div className="card-body">
                  <h5 className="card-title">Agudeza Visual</h5>
                  <table
                    style={{
                      width: "50%",
                      margin: "2px auto",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderCollapse: "collapse",
                    }}
                    className="tablaQuerato"
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "0px solid black",
                          }}
                        ></td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          AV:
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          CV:
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OD 20/
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.AvOd}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.CvOd}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OI 20/
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.AvOi}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.CvOi}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {ultimaVisita.QueratoOd1 ||
            ultimaVisita.QueratoOd2 ||
            ultimaVisita.QueratoOd3 ||
            ultimaVisita.QueratoOi1 ||
            ultimaVisita.QueratoOi2 ||
            ultimaVisita.QueratoOi3 ? (
              <div className="card bg-light text-black mb-3">
                <div className="card-body">
                  <h5 className="card-title">Queratometría</h5>
                  <table
                    style={{
                      width: "50%",
                      margin: "2px auto",
                      fontSize: "14px",
                      fontWeight: "bold",
                      borderCollapse: "collapse",
                    }}
                    className="tablaQuerato"
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OD /
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOd1}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOd2}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          <strong>X</strong>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOd3}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          OI /
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOi1}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOi2}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          <strong>X</strong>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {ultimaVisita.QueratoOi3}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {ultimaVisita.observaciones !== null &&
        ultimaVisita.observaciones !== "" ? (
          <div className="card bg-light text-black mb-3">
            <div className="card-body">
              <h5 className="card-title">Observaciones</h5>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {ultimaVisita.observaciones}
              </div>
            </div>
          </div>
        ) : null}
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Imágenes de la Visita</h5>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imagenes.length > 0 ? (
                imagenes.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={imgSrc}
                    alt={`Imagen de la visita ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "250px",
                      objectFit: "cover",
                      cursor: "pointer",
                      margin: "auto",
                    }}
                    onClick={() => openModal(imgSrc)}
                  />
                ))
              ) : (
                <p>{messagePhotos}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img src={selectedImage} alt="Imagen grande" />
            <button onClick={closeModal} className="close-modal">
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UltimaVisitaHistorial;
