import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import "../../index.css";
import logo from "../../images/LogoTransparente.png";

const HistoriaClinicaView = () => {
  const [historiaClinica, setHistoriaClinica] = useState(null);
  const [loading, setLoading] = useState(true);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const idCliente = params.get("cl");

  useEffect(() => {
    document.body.style.paddingTop = "0";
    const fetchUltimaVisita = async () => {
      try {
        const response = await axios.get(
          `/historiaClinica/historiaByID/${idCliente}`
        );
        setHistoriaClinica(response.data.historia);

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la última historia:", error);
        Swal.fire({
          icon: "warning",
          title: "Error al obtener la última visita",
          text: "Hubo un problema al cargar los detalles de la última visita. Por favor, inténtalo de nuevo más tarde.",
        });
        setLoading(false);
      }
    };

    fetchUltimaVisita();
  }, [idCliente]);

  if (loading) {
    return <div>Cargando historico...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const formatDateWithoutTime = (dateString) => {
    const formattedDate = formatDate(dateString);
    return formattedDate.split(",")[0];
  };

  function formatNumber(number) {
    number = parseInt(number);
    const [integerPart, decimalPart] = number.toFixed(2).split(".");
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${integerWithCommas}.${decimalPart}`;
  }

  function positiveOrNegative(value) {
    const val = parseFloat(value);
    if (val >= 0) {
      return "+" + val;
    } else if (val < 0) {
      return val;
    }
  }

  const optoEstilos = {
    position: "absolute",
    right: "50px",
    top: "100px",
  };

  const retinoExist =
    historiaClinica.QueratoOd1 !== "" ||
    historiaClinica.QueratoOd2 !== "" ||
    historiaClinica.QueratoOd3 !== "" ||
    historiaClinica.QueratoOi1 !== "" ||
    historiaClinica.QueratoOi2 !== "" ||
    historiaClinica.QueratoOi3 !== "";
  let marginTotalGrad = "";
  if (retinoExist) {
    marginTotalGrad = "40px";
  } else {
    marginTotalGrad = "0px";
  }
  return (
    <div
      style={{
        marginTop: "30px",
        color: "black",
        padding: "0 50px",
        display: "grid",
        position: "relative",
        width: "1100px",
      }}
      id="content"
      className="FuenteExportarHistorico"
    >
      <img
        src={logo}
        style={{ width: "120px", position: "absolute", left: "20px" }}
      />

      {historiaClinica.optometrista !== "" ? (
        <div style={optoEstilos}>
          <div style={{ fontSize: "18px" }}>
            <strong>Optometrista:</strong> {historiaClinica.optometrista}
          </div>

          <div style={{ fontSize: "18px" }}>
            <strong>Cedula profesional:</strong> {historiaClinica.cedula}
          </div>
        </div>
      ) : null}

      <h1
        style={{
          fontSize: "34px",
          textAlign: "center",
          fontWeight: "700",
        }}
      >
        Historia Clínica
      </h1>
      <div style={{ marginTop: "50px", fontSize: "18px" }}>
        <strong>Fecha:</strong>{" "}
        {new Intl.DateTimeFormat("es-ES", {
          timeZone: "America/Mexico_City", // Zona horaria de México
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date())}
      </div>
      <div
        style={{
          display: "grid",
          marginBottom: "10px",
          marginTop: "0px",
          width: "600px",
          fontSize: "18px",
          borderBottom: "1px solid black",
          width: "100%",
        }}
      >
        <span style={{ fontWeight: "700", marginBottom: "0px" }}>
          Nombre:{" "}
          <span style={{ fontWeight: "400", marginBottom: "5px" }}>
            {" "}
            {historiaClinica.NombreCliente}
          </span>
        </span>
        <span style={{ fontWeight: "700", marginBottom: "0px" }}>
          Edad:{" "}
          <span style={{ fontWeight: "400", marginBottom: "5px" }}>
            {" "}
            {historiaClinica.edadCliente}
          </span>
        </span>
        <span style={{ fontWeight: "700", marginBottom: "5px" }}>
          Tel:{" "}
          <span style={{ fontWeight: "400", marginBottom: "5px" }}>
            {" "}
            {historiaClinica.telefonoCliente}
          </span>
        </span>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            fontSize: "18px",
          }}
        >
          <div>
            <strong>Dificultad a lo lejos:</strong>{" "}
            {historiaClinica.DificultadLejos}
          </div>
          <div>
            <strong>Dificultad a lo cerca:</strong>{" "}
            {historiaClinica.DificultadCerca}
          </div>
          <div>
            <strong>Dolor de cabeza:</strong> {historiaClinica.DolorCabeza}
          </div>
          <div>
            <strong>Irritación:</strong> {historiaClinica.Irritacion}
          </div>
          <div>
            <strong>Diabetes:</strong> {historiaClinica.Diabetes}
          </div>
          <div>
            <strong>Hipertensión:</strong> {historiaClinica.Hipertension}
          </div>
        </div>
      </div>

      {historiaClinica.observaciones !== "" ? (
        <div
          style={{ maxWidth: "900px", textAlign: "justify", fontSize: "18px" }}
        >
          <strong>Observaciones:</strong>
          <br /> {historiaClinica.observaciones}
        </div>
      ) : null}

      <h5
        style={{
          fontWeight: "700",
          marginTop: "20px",
          borderTop: "1px solid black",
          paddingTop: "10px",
        }}
      >
        Graduación Anterior
      </h5>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          marginBottom: "0px",
          width: "100%",
        }}
      >
        <table style={{ textAlign: "center" }} className="tablaVistaHistorico">
          <thead>
            <tr>
              <th> &nbsp;</th>
              <th style={{ textAlign: "center" }}>ESF</th>
              <th style={{ textAlign: "center" }}>CIL</th>
              <th style={{ textAlign: "center" }}>EJE</th>
              <th style={{ textAlign: "center" }}>ADD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>OD</td>
              <td>{positiveOrNegative(historiaClinica.EsfOd)}</td>
              <td>{positiveOrNegative(historiaClinica.CilOd)}</td>
              <td>{positiveOrNegative(historiaClinica.EjeOd)}</td>
              <td>{positiveOrNegative(historiaClinica.AddOd)}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>OI</td>
              <td>{positiveOrNegative(historiaClinica.EsfOi)}</td>
              <td>{positiveOrNegative(historiaClinica.CilOi)}</td>
              <td>{positiveOrNegative(historiaClinica.EjeOi)}</td>
              <td>{positiveOrNegative(historiaClinica.AddOi)}</td>
            </tr>
          </tbody>
        </table>
        {historiaClinica.AvOd !== "" ||
        historiaClinica.CvOd !== "" ||
        historiaClinica.AvOi !== "" ||
        historiaClinica.CvOi !== "" ? (
          <table
            className="tablaVistaHistorico"
            style={{ width: "300px", marginLeft: "40px" }}
          >
            <thead>
              <tr>
                <th
                  style={{ border: "0", textAlign: "right", paddingRight: "0" }}
                >
                  A/V
                </th>
                <th style={{ textAlign: "left", border: "0" }}></th>
                <th
                  style={{ border: "0", textAlign: "right", paddingRight: "0" }}
                >
                  C/V
                </th>
                <th style={{ textAlign: "left", border: "0" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "right", border: "0" }}>OD 20/</td>
                <td
                  style={{ border: "0", textAlign: "left", paddingLeft: "0" }}
                >
                  {historiaClinica.AvOd}
                </td>
                <td style={{ textAlign: "right", border: "0" }}>OD 20/</td>
                <td
                  style={{ border: "0", textAlign: "left", paddingLeft: "0" }}
                >
                  {historiaClinica.CvOd}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "right", border: "0" }}>OI 20/</td>
                <td
                  style={{ border: "0", textAlign: "left", paddingLeft: "0" }}
                >
                  {historiaClinica.AvOi}
                </td>
                <td style={{ textAlign: "right", border: "0" }}>OI 20/</td>
                <td
                  style={{ border: "0", textAlign: "left", paddingLeft: "0" }}
                >
                  {historiaClinica.CvOi}
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          borderBottom: "1px solid black",
        }}
      >
        {historiaClinica.RetinosOd !== "" ||
        historiaClinica.RetinosOi !== "" ? (
          <div style={{ marginRight: "40px" }}>
            <h5 style={{ fontWeight: "700" }}>Retinoscopia</h5>
            <table style={{ width: "200px" }} className="tablaVistaHistorico">
              <thead>
                <tr>
                  <th>OD</th>
                  <th>OI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{positiveOrNegative(historiaClinica.RetinosOd)}</td>
                  <td>{positiveOrNegative(historiaClinica.RetinosOi)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        {historiaClinica.QueratoOd1 !== "" ||
        historiaClinica.QueratoOd2 !== "" ||
        historiaClinica.QueratoOd3 !== "" ||
        historiaClinica.QueratoOi1 !== "" ||
        historiaClinica.QueratoOi2 !== "" ||
        historiaClinica.QueratoOi3 !== "" ? (
          <div>
            <h5 style={{ fontWeight: "700", marginLeft: "310px" }}>
              Queratometría
            </h5>
            <table
              style={{ width: "250px", marginLeft: "310px" }}
              className="tablaVistaHistorico"
            >
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                {historiaClinica.QueratoOd1 !== "" ||
                historiaClinica.QueratoOd2 !== "" ||
                historiaClinica.QueratoOd3 !== "" ? (
                  <tr>
                    <td>OD / {historiaClinica.QueratoOd1}</td>
                    <td style={{ borderRight: "0" }}>
                      {historiaClinica.QueratoOd2}
                    </td>
                    <td style={{ border: "0", borderTop: "1px solid #acacac" }}>
                      X
                    </td>
                    <td style={{ borderLeft: "0" }}>
                      {historiaClinica.QueratoOd3}
                    </td>
                  </tr>
                ) : null}

                {historiaClinica.QueratoOi1 !== "" ||
                historiaClinica.QueratoOi2 !== "" ||
                historiaClinica.QueratoOi3 !== "" ? (
                  <tr>
                    <td>OI / {historiaClinica.QueratoOi1}</td>
                    <td style={{ borderRight: "0" }}>
                      {historiaClinica.QueratoOi2}
                    </td>
                    <td style={{ border: "0", borderTop: "1px solid #acacac" }}>
                      X
                    </td>
                    <td style={{ borderLeft: "0" }}>
                      {historiaClinica.QueratoOi3}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div>
          <h5 style={{ fontWeight: "700" }}>Graduación Total</h5>
          <table
            style={{ textAlign: "center", width: "400px", marginRight: "50px" }}
            className="tablaVistaHistorico"
          >
            <thead>
              <tr>
                <th> &nbsp;</th>
                <th style={{ textAlign: "center" }}>ESF</th>
                <th style={{ textAlign: "center" }}>CIL</th>
                <th style={{ textAlign: "center" }}>EJE</th>
                <th style={{ textAlign: "center" }}>ADD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>OD</td>
                <td>{positiveOrNegative(historiaClinica.EsfOdTot)}</td>
                <td>{positiveOrNegative(historiaClinica.CilOdTot)}</td>
                <td>{positiveOrNegative(historiaClinica.EjeOdTot)}</td>
                <td>{positiveOrNegative(historiaClinica.AddOdTot)}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>OI</td>
                <td>{positiveOrNegative(historiaClinica.EsfOiTot)}</td>
                <td>{positiveOrNegative(historiaClinica.CilOiTot)}</td>
                <td>{positiveOrNegative(historiaClinica.EjeOiTot)}</td>
                <td>{positiveOrNegative(historiaClinica.AddOiTot)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {historiaClinica.aoData !== "" || historiaClinica.diData !== "" ? (
          <div
            style={{
              display: "grid",
              justifyContent: "start",
              marginBottom: "0px",
            }}
          >
            <div style={{ marginRight: "0px", display: "grid" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "start",
                  marginTop: "0px",
                  alignSelf: "end",
                }}
              >
                DI:{" "}
                <span style={{ fontWeight: "500" }}>
                  {historiaClinica.diData}
                </span>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "0px",
                }}
              >
                AO:{" "}
                <span style={{ fontWeight: "500" }}>
                  {historiaClinica.aoData}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          fontSize: "18px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", width: "600px" }}>
          <div>
            <strong>Armazón:</strong> {historiaClinica.armazon}
          </div>
          <div style={{ marginLeft: "30px" }}>
            <strong>Cristal:</strong> {historiaClinica.cristal}
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div>
            <strong>Tipo de lente / visión:</strong>{" "}
            {historiaClinica.tipoArmazon}
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {historiaClinica.tipo !== "" ? (
            <div>
              <strong>Tipo:</strong> {historiaClinica.tipo}
            </div>
          ) : null}

          {historiaClinica.color !== "" ? (
            <div style={{ marginLeft: "10px" }}>
              <strong>Color:</strong> {historiaClinica.color}
            </div>
          ) : null}
        </div>
      </div>

      {historiaClinica.lenteContactoDes !== "" ? (
        <div style={{ marginTop: "10px", fontSize: "18px" }}>
          <strong>Lente de contacto (Descripción):</strong>{" "}
          {historiaClinica.lenteContactoDes}
        </div>
      ) : null}

      <div style={{ display: "flex", marginTop: "30px" }}>
        <div>
          <h5 style={{ fontWeight: "700" }}>Graduación Prescrita</h5>
          <table
            style={{ textAlign: "center", width: "400px", marginRight: "50px" }}
            className="tablaVistaHistorico"
          >
            <thead>
              <tr>
                <th> &nbsp;</th>
                <th style={{ textAlign: "center" }}>ESF</th>
                <th style={{ textAlign: "center" }}>CIL</th>
                <th style={{ textAlign: "center" }}>EJE</th>
                <th style={{ textAlign: "center" }}>ADD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>OD</td>
                <td>{positiveOrNegative(historiaClinica.EsfOdPre)}</td>
                <td>{positiveOrNegative(historiaClinica.CilOdPre)}</td>
                <td>{positiveOrNegative(historiaClinica.EjeOdPre)}</td>
                <td>{positiveOrNegative(historiaClinica.AddOdPre)}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>OI</td>
                <td>{positiveOrNegative(historiaClinica.EsfOiPre)}</td>
                <td>{positiveOrNegative(historiaClinica.CilOiPre)}</td>
                <td>{positiveOrNegative(historiaClinica.EjeOiPre)}</td>
                <td>{positiveOrNegative(historiaClinica.AddOiPre)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {historiaClinica.ao2Data !== "" || historiaClinica.di2Data !== "" ? (
          <div
            style={{
              display: "grid",
              justifyContent: "start",
              marginBottom: "0px",
            }}
          >
            <div style={{ marginRight: "0px", display: "grid" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "start",
                  marginTop: "0px",
                  alignSelf: "end",
                }}
              >
                DI:{" "}
                <span style={{ fontWeight: "500" }}>
                  {historiaClinica.di2Data}
                </span>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "0px",
                }}
              >
                AO:{" "}
                <span style={{ fontWeight: "500" }}>
                  {historiaClinica.ao2Data}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div style={{ marginTop: "0px", fontSize: "20px" }}>
        {historiaClinica.total !== "" ? (
          <div>
            <strong>Total:</strong> ${formatNumber(historiaClinica.total)}
          </div>
        ) : null}

        {historiaClinica.anticipo !== "" ? (
          <div>
            <strong>Anticipo:</strong> ${formatNumber(historiaClinica.anticipo)}
          </div>
        ) : null}

        {historiaClinica.saldo !== "" ? (
          <div>
            <strong>Saldo:</strong> ${formatNumber(historiaClinica.saldo)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HistoriaClinicaView;
