import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import "../../index.css";


const Historial = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cliente = location.state?.Cliente;
  const [visitasList, setVisitasList] = useState([]);

  if (!cliente) {
    navigate("/dashboard/clientes");
  }

  useState(() => {
    axios.get(`/HistorialVisitas/${cliente.id}`).then((response) => {
      setVisitasList(response.data);
    });
  }, []);

  const goToVisitaDetalle = (visita) => {
    navigate("/visita/historialDetalle", { state: { visita} });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const handleRegresar = () => {
    navigate(-1);
  };

  return (
    <div
      style={{
        marginTop: "50px",
        color: "black",
        padding: "0 50px",
        display: "grid",
      }}
    >
      <Header></Header>

      <button
        className="btn btn-sm btn-primary"
        onClick={handleRegresar}
        style={{ width: "200px", justifySelf: "end", marginRight: "5vw", marginBottom: '40px', marginTop: '0px' }}
      >
        Regresar
      </button>
      <p
        style={{
          fontSize: "23px",
          textAlign: "center",
          fontWeight: "700",
          textDecoration: "underline",
          marginBottom: '40px'
        }}
      >
        Historial de Visitas:{" "}
        <span style={{ fontWeight: "500" }}>{cliente?.nombreCompleto}</span>
      </p>
      {visitasList.length > 0 ? (
        <table className="table table-dark" style={{ borderRadius: "20px", maxWidth: '1500px', justifySelf: 'center' }}>
          <thead>
            <tr>
              <th>Sucursal</th>
              <th>Optometrista</th>
              <th>Cedula</th>
              <th>Fecha</th>
              <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {visitasList.map((visita) => (
              <tr key={visita.IdVisita} className="tablaPedidos">
                <td>{visita.NombreSucursal || ""}</td>
                <td>{visita.optometrista || ""}</td>
                <td>{visita.cedula || ""}</td>
                <td>{formatDate(visita.CreateDate)}</td>
                <td>
                  <button className="btn btn-primary" onClick={()=> goToVisitaDetalle(visita)}>Ver</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 style={{ textAlign: "center", color: "lightgray" }}>
          No hay visitas en el historial del cliente
        </h4>
      )}
    </div>
  );
};

export default Historial;
