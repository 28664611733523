import React, { useState, useEffect } from "react";
import axios from "../api/axiosConfig";
import Swal from "sweetalert2";
import accionesIcon from "../images/setting.png";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import Loader from "./loader";
import "../index.css";

const Clientes = () => {
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [edad, setEdad] = useState("");
  const [sexo, setSexo] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [telefono, setTelefono] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [email, setEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [clientesList, setClientesList] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sucursalesList, setSucursalesList] = useState([]);
  const userType = localStorage.getItem("userType");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalClientes, setTotalClientes] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentClientes, setCurrentClientes] = useState(0);

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };
  // Load initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/clientes");
        setLoading(true);
        const fetchedClientes = response.data.data;
        setLoading(false);
        setClientesList(fetchedClientes);

        // Calcula las páginas totales basadas en el número de clientes inicial
        const totalPages = Math.ceil(fetchedClientes.length / clientesPerPage);
        setTotalClientes(totalPages);

        // Filtra los clientes iniciales a mostrar
        setCurrentClientes(fetchedClientes.slice(0, clientesPerPage));
      } catch (error) {
        setLoading(false);
        console.error("Error fetching clients", error);
      }
    };

    const fetchDataPorSucursal = async () => {
      try {
        const response = await axios.get(
          `/clientesPorSuc/${localStorage.getItem("branchId")}`
        );
        const fetchedClientes = response.data.data;
        setClientesList(fetchedClientes);

        // Calcula las páginas totales basadas en el número de clientes inicial
        const totalPages = Math.ceil(fetchedClientes.length / clientesPerPage);
        setTotalClientes(totalPages);

        // Filtra los clientes iniciales a mostrar
        setCurrentClientes(fetchedClientes.slice(0, clientesPerPage));
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    };

    if (userType === "0") {
      fetchData();
    } else {
      fetchDataPorSucursal();
    }

    fetchSucursales();
  }, [userType]);

  const fetchSucursales = () => {
    axios
      .get("/sucursales/active")
      .then((response) => {
        setSucursalesList(response.data.data);
      })
      .catch((error) => {
        console.error("Error al obtener sucursales:", error);
      });
  };

  const handleCreateOrUpdate = async (event) => {
    event.preventDefault();
    let sucursalId = localStorage.getItem("branchId");
    const cliente = {
      id: editMode,
      nombreCompleto,
      fechaNacimiento: fechaNacimiento || null,
      edad: edad || null,
      sexo: sexo || null,
      domicilio: domicilio || null,
      telefono: telefono || null,
      telefono2: telefono2 || null,
      email: email || null,
      rfc: rfc || null,
      codigoPostal: codigoPostal || null,
      sucursal: sucursalId || null,
    };

    try {
      if (editMode !== null) {
        await axios.put(`/clientes/${editMode}`, cliente);
        const updatedClientesList = clientesList.map((c) =>
          c.id === editMode ? cliente : c
        );
        setClientesList(updatedClientesList);
        setCurrentClientes(updatedClientesList);
        Swal.fire(
          "¡Actualizado con éxito!",
          "El paciente ha sido actualizado.",
          "success"
        );
      } else {
        const response = await axios.post("/clientes/crearCliente", cliente); // Cliente recién creado
        setClientesList([...clientesList, cliente]); // Agregar el nuevo cliente a la lista existente
        setCurrentClientes([...currentClientes, cliente]); // Actualizar la lista de clientes actual
        Swal.fire(
          "¡Creado con éxito!",
          "El paciente ha sido creado.",
          "success"
        ).then(()=>{
          window.location.reload();
        });
      }
      resetForm();
    } catch (error) {
      console.error("Error saving paciente", error);
    }
  };

  const handleEdit = (cliente) => {
    const fechaNacimientoOnlyDate =
      cliente.fechaNacimiento !== null
        ? cliente.fechaNacimiento.split("T")[0]
        : "";
    setNombreCompleto(cliente.nombreCompleto || "");
    setFechaNacimiento(fechaNacimientoOnlyDate || "");
    setEdad(cliente.edad || "");
    setSexo(cliente.sexo || "");
    setDomicilio(cliente.domicilio || "");
    setTelefono(cliente.telefono || "");
    setTelefono2(cliente.telefono2 || "");
    setEmail(cliente.email || "");
    setRfc(cliente.rfc || "");
    setCodigoPostal(cliente.codigoPostal || "");
    setEditMode(cliente.id);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Eliminar paciente",
      text: "¿Estás seguro de que deseas eliminar al paciente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`/clientes/${id}`);
          if (response.data.success) {
            // Si la solicitud fue exitosa, actualiza la lista de clientes
            const updatedClientesList = clientesList.filter(
              (cliente) => cliente.id !== id
            );
            setClientesList(updatedClientesList);
            setCurrentClientes(updatedClientesList); // Actualizar la lista de clientes actual
            // Muestra un mensaje de éxito al usuario
            Swal.fire({
              title: "Paciente eliminado!",
              text: "El paciente ha sido eliminado exitosamente.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            // Si la solicitud no fue exitosa, muestra un mensaje de error al usuario
            Swal.fire({
              title: "¡Error!",
              text: "No se pudo eliminar el paciente.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          // Si hubo un error en la solicitud, muestra un mensaje de error al usuario
          console.error("Error al eliminar el usuario:", error);
          Swal.fire({
            title: "¡Error!",
            text: "Ocurrió un error al intentar eliminar el paciente.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        Swal.close();
      }
    });
  };

  const resetForm = () => {
    setNombreCompleto("");
    setFechaNacimiento("");
    setEdad("");
    setSexo("");
    setDomicilio("");
    setTelefono("");
    setTelefono2("");
    setEmail("");
    setRfc("");
    setCodigoPostal("");
    setEditMode(null);
  };

  // Dentro de la función handleSearch
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    // Siempre comienza desde la primera página al buscar
    setCurrentPage(1);
    setCurrentClientes(filterClientes(1, searchTerm));
  };

  // Función para filtrar clientes por nombre de manera flexible
  const filterClientes = (page, searchTermR) => {
    console.log(searchTermR);
    setSearchTerm(searchTermR);
    const registrosIni = (page - 1) * clientesPerPage;

    const filtered = clientesList.filter((cliente) => {
      // Verifica que `nombreCompleto` sea una cadena antes de filtrar
      return typeof cliente.nombreCompleto === 'string' &&
             cliente.nombreCompleto.toLowerCase().includes(searchTermR.toLowerCase());
    });

    const totalPages = Math.ceil(filtered.length / clientesPerPage);
    setTotalClientes(totalPages);

    return filtered.slice(registrosIni, registrosIni + clientesPerPage);
};

  const handleNewCita = (id, clienteName) => {
    // Función para cargar las citas existentes
    const cargarCitas = () => {
      const fechaCita = document.getElementById("fechaCita").value;
      const sucursalCita = document.getElementById("sucursalCita").value;

      if (!fechaCita) {
        return;
      }

      // Realizar petición para obtener las citas del día seleccionado
      axios
        .get("/citas/disponibilidad", {
          params: { fecha: fechaCita, sucursalId: sucursalCita },
        })
        .then((response) => {
          const { results } = response.data;
          const tablaCitas = document.getElementById("tablaCitas");

          // Limpiar la tabla
          tablaCitas.innerHTML = `
            <thead>
              <tr>
                <th style="padding:5px">Hora</th>
                <th style="padding:5px">Paciente</th>
                <th style="padding:5px">Teléfono</th>
                <th style="padding:5px">Confirmada</th>
                <th style="padding:5px">Cancelada</th>
                <th style="padding:5px">Creador</th>
              </tr>
            </thead>
            <tbody id="bodyCitasT"></tbody>
          `;

          const tbody = tablaCitas.querySelector("tbody");

          if (results.length > 0) {
            results.forEach((cita) => {
              const tr = document.createElement("tr");

              tr.innerHTML = `
                <td>${cita.hora}</td>
                <td>${cita.clienteNombre}</td>
                <td>${cita.telefonoCliente}</td>
                <td>${cita.confirmada == "0" ? "No" : "Si"}</td>
                <td>${cita.cancelada == "0" ? "No" : "Si"}</td>
                <td>${cita.nombreCreador}</td>
              `;

              tbody.appendChild(tr);
            });
          } else {
            tbody.innerHTML =
              "<tr><td colspan='6' style='text-align:center;'>No hay citas para esta fecha.</td></tr>";
          }
        })
        .catch((error) => {
          console.error("Error al obtener las citas existentes:", error);
          Swal.fire("¡Error!", "Hubo un error al cargar las citas.", "error");
        });
    };

    Swal.fire({
      title: "Nueva Cita",
      html: `
      <div class="ContenedorNuevaCita" style="display: flex; flex-direction: column;">
        <div class="form-container" style="margin-bottom: 20px;">
          <label for="fechaCita" style="font-weight:700;">Fecha</label>
          <input type="date" id="fechaCita" class="swal2-input" style="margin-top:5px; margin-bottom:15px; margin-left:5px;">
    
          <label for="sucursalCita" style="font-weight:700;margin-right:5px;">Sucursal</label>
          <select id="sucursalCita" class="swal2-input limited-width-select" style="margin-bottom:15px; margin-right:25px">
            <option value="">Selecciona una sucursal</option>
            ${sucursalesList
              .map(
                (suc) =>
                  `<option value="${suc.id}">${suc.sucursalNumber} - ${suc.direccion}</option>`
              )
              .join("")}
          </select>
          <label for="horaCita" style="font-weight:700;">Hora</label>
          <input type="time" id="horaCita" class="swal2-input" step="900" min="00:00" max="23:45" style="margin-top:5px;margin-left:5px;">
          <br> <!-- Intervalo de 15 minutos -->
          <label for="telCliente" style="font-weight:700;">Teléfono Paciente</label>
          <input type="text" id="telCliente" class="swal2-input" maxlength="10" style="margin-top:5px;margin-left:5px;" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10);" placeholder="Teléfono (10 dígitos)">
        </div>
    
        <!-- Aquí mostraremos las citas existentes en una tabla -->
        <p style="font-weight:700; font-size: 22px;">Citas para el día seleccionado:</p>
        <div class="citas-container" style="max-height: 300px; overflow-y: auto;">
          <table id="tablaCitas" style="border-collapse: collapse; text-align: left;">
            <thead>
              <tr>
                <th style="padding:5px">Hora</th>
                <th style="padding:5px">Paciente</th>
                <th style="padding:5px">Teléfono</th>
                <th style="padding:5px">Confirmada</th>
                <th style="padding:5px">Cancelada</th>
                <th style="padding:5px">Creador</th>
              </tr>
            </thead>
            <tbody id="bodyCitasT"></tbody>
          </table>
        </div>
      </div>
      `,
      customClass: {
        popup: "custom-modal",
      },
      width: "1300px",
      focusConfirm: false,
      didOpen: () => {
        const fechaInput = document.getElementById("fechaCita");
        const sucursalSelect = document.getElementById("sucursalCita");

        // Evento para cargar citas cuando cambia la fecha o la sucursal
        fechaInput.addEventListener("change", () => {
          cargarCitas();
        });
        sucursalSelect.addEventListener("change", () => {
          cargarCitas();
        });
      },
      preConfirm: async () => {
        const fechaCita = document.getElementById("fechaCita").value;
        const horaCita = document.getElementById("horaCita").value;
        const sucursalCita = document.getElementById("sucursalCita").value;
        const telCliente = document.getElementById("telCliente").value;
    
        if (!fechaCita || !horaCita || !sucursalCita || !telCliente) {
          Swal.showValidationMessage("Por favor completa todos los campos");
          return false;
        }
    
        try {
          setLoading(true);
          const response = await axios.get("/citas/disponibilidad", {
            params: { fecha: fechaCita, sucursalId: sucursalCita },
          });
    
          const { results } = response.data;
    
          // Verificar si la hora seleccionada ya está ocupada
          const horasOcupadas = results.map((cita) => cita.hora.substring(0, 5));
    
          if (horasOcupadas.includes(horaCita)) {
            setLoading(false);
            Swal.showValidationMessage("Esta hora ya está ocupada.");
            return false; // Evitar que se cierre el modal si la hora está ocupada
          }
    
          // Si la hora no está ocupada, se continúa con la creación de la cita
          return { fechaCita, horaCita, sucursalCita, telCliente };
        } catch (error) {
          console.error("Error consultando disponibilidad:", error);
          setLoading(false);
          Swal.showValidationMessage("Error al verificar disponibilidad.");
          return false; // Mantener el modal abierto en caso de error
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { fechaCita, horaCita, sucursalCita, telCliente } = result.value;

        let sucDirecc = sucursalesList.filter(
          (sucursales) => sucursales.id === parseInt(sucursalCita)
        );

        // Asegurarte de obtener la dirección de la primera coincidencia del filtro
        sucDirecc = sucDirecc.length > 0 ? sucDirecc[0].direccion : null;

        // Crear la cita si la hora está disponible
        const nuevaCita = {
          fecha: fechaCita,
          hora: horaCita,
          sucursalId: sucursalCita,
          sucursalDireccion: sucDirecc,
          idCliente: id,
          clienteName: clienteName,
          telefonoCliente: telCliente,
        };
        const fechaEntrega = new Date(nuevaCita.fecha);
        const dia = fechaEntrega.getDate().toString().padStart(2, '0');
        const mes = (fechaEntrega.getMonth() + 1).toString().padStart(2, '0'); 
        const anio = fechaEntrega.getFullYear().toString().slice(-2); 
        const Fecha = `${dia}/${mes}/${anio}`;
        const nombreOptica = "Opticos Pelayo";
        const phoneNumber = "+52" + nuevaCita.telefonoCliente;
        const nombreCliente = nuevaCita.clienteName;
        const Hora = nuevaCita.hora;
        axios.post("/whatsapp/AvisoCreacionCita", { Fecha, Hora, nombreOptica, nombreCliente, phoneNumber }); 
        axios
          .post("/citas", nuevaCita, {
            headers: {
              iduser: localStorage.getItem("idUser"),
              name: localStorage.getItem("name"),
              lastname: localStorage.getItem("lastName"),
              secondLastName: localStorage.getItem("secondLastName"),
            },
          })
          .then(() => {
            setLoading(false);
            Swal.fire(
              "¡Cita Creada!",
              "La cita ha sido creada exitosamente.",
              "success"
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error creando la cita:", error);
            Swal.fire("¡Error!", "Hubo un error al crear la cita.", "error");
          });
      }
    });
  };

  const navigate = useNavigate();
  const handleNewPedido = (cliente, sucursales) => {
    navigate("/dashboard/crearPedido", { state: { cliente, sucursales } });
  };

  const handleNewVisita = (cliente, sucursales) => {
    navigate("/historial/nuevaVisita", { state: { cliente, sucursales } });
  };

  const handleNewHistoria = (cliente, sucursales) => {
    navigate("/historial/nuevaHistoriaClinica", {
      state: { cliente, sucursales },
    });
  };

  const getHistoriaPDF = async (Cliente) => {
    const idCliente = Cliente.id;
    setLoading(true);
    try {
      const response = await axios.post(
        "/historiaClinica/validarHistoriaClinica",
        { idCliente }
      );
      const { existeHistoriaClinica } = response.data;
      if (existeHistoriaClinica) {
        const res = await axios.post("/historiaClinica/generatePDF", {
          idCliente,
        });
        // Obtener la URL del PDF desde la respuesta
        const pdfUrl = res.data.pdfUrl;
        setLoading(false);
        // Abre la URL del PDF en una nueva pestaña
        window.open(pdfUrl, "_blank");
      } else {
        setLoading(false);
        Swal.fire(
          "¡Error!",
          "No se encontraron registros para el paciente seleccionado.",
          "error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error al generar el PDF:", error);
      alert("Hubo un problema al generar el PDF.");
    }
  };

  
  const goToHistorialVisitas = async (Cliente) => {
    navigate("/visita/historial", { state: { Cliente} });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentClientes(filterClientes(page, searchTerm));
  };

  return (
    <div>
      <Header />
      <div
        className="container formCliente"
        style={{ marginTop: "50px", marginLeft: "0" }}
      >
        <h2 className="mt-4 mb-3">Gestión de Pacientes</h2>
        <form onSubmit={handleCreateOrUpdate}>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Nombre Completo:*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Nombre Completo"
                value={nombreCompleto}
                onChange={(e) =>
                  setNombreCompleto(e.target.value.toUpperCase())
                }
                required
              />
            </div>
            <div className="col-md-4">
              <span>Fecha de Nacimiento:</span>
              <input
                type="date"
                className="form-control"
                placeholder="Ingresa Fecha de Nacimiento"
                value={fechaNacimiento}
                onChange={(e) => setFechaNacimiento(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>Sexo:</span>
              <select
                className="form-control"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option value="">Seleccionar Sexo</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Edad:*</span>
              <input
                type="number"
                className="form-control"
                placeholder="Ingresa Edad"
                value={edad}
                onChange={(e) => setEdad(e.target.value)}
                required
              />
            </div>
            <div className="col-md-4">
              <span>Domicilio:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Domicilio"
                value={domicilio}
                onChange={(e) => setDomicilio(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>Teléfono:*</span>
              <input
                type="tel"
                className="form-control"
                placeholder="Ingresa Teléfono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                pattern="[0-9]{10}"
                maxLength={10}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Segundo Teléfono:</span>
              <input
                type="tel"
                className="form-control"
                placeholder="Ingresa Segundo Teléfono"
                value={telefono2}
                onChange={(e) => setTelefono2(e.target.value)}
                pattern="[0-9]{10}"
                maxLength={10}
              />
            </div>
            <div className="col-md-4">
              <span>Email:</span>
              <input
                type="email"
                className="form-control"
                placeholder="Ingresa Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>RFC:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa RFC"
                value={rfc}
                onChange={(e) => setRfc(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Código Postal:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Código Postal"
                value={codigoPostal}
                onChange={(e) => setCodigoPostal(e.target.value)}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {editMode ? "Actualizar" : "Crear"}
          </button>
        </form>
      </div>
      <div style={{ margin: "0 2%" }} className="formCliente">
        <h3 className="mt-5">Lista de Pacientes</h3>
        <div className="row mb-3" style={{ width: "1000px" }}>
          <div className="col-md-4">
            <span>Buscar por Nombre:</span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa el nombre"
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm !== "" && (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  window.location.reload();
                }}
                style={{ marginTop: "8px" }}
              >
                <i className="bi bi-x-lg">Borrar búsqueda</i>
              </button>
            )}
          </div>
        </div>
        {currentClientes.length > 0 ? (
          <>
            <table
              className="table table-dark"
              style={{ borderRadius: "20px" }}
            >
              <thead>
                <tr>
                  <th style={{ borderTopLeftRadius: "20px" }}>
                    Nombre Completo
                  </th>
                  <th>Fecha de Nacimiento</th>
                  <th>Edad</th>
                  <th>Sexo</th>
                  <th>Domicilio</th>
                  <th>Teléfono</th>
                  <th>Segundo Teléfono</th>
                  <th>Email</th>
                  <th>RFC</th>
                  <th>Código Postal</th>
                  <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {currentClientes.map((cliente) => (
                  <tr key={cliente.id} className="tablaPedidos">
                    <td>{cliente.nombreCompleto}</td>
                    <td>
                      {cliente.fechaNacimiento
                        ? new Date(cliente.fechaNacimiento).toLocaleDateString()
                        : "Sin Fecha"}
                    </td>
                    <td>{cliente.edad}</td>
                    <td>{cliente.sexo}</td>
                    <td>{cliente.domicilio}</td>
                    <td>{cliente.telefono}</td>
                    <td>{cliente.telefono2}</td>
                    <td>{cliente.email}</td>
                    <td>{cliente.rfc}</td>
                    <td>{cliente.codigoPostal}</td>
                    <td>
                      <div className="dropdown">
                        <a
                          className="dropbtn"
                          onClick={() => toggleDropdown(cliente.id)}
                        >
                          <img
                            className="GearIcon"
                            src={accionesIcon}
                            style={{ width: "120px", margin: "auto" }}
                          />
                        </a>
                        {openDropdown === cliente.id && (
                          <div className="dropdown-content">
                            <button onClick={() => handleEdit(cliente)}>
                              Editar
                            </button>
                            <button
                              onClick={() =>
                                handleNewCita(
                                  cliente.id,
                                  cliente.nombreCompleto
                                )
                              }
                            >
                              Nueva Cita
                            </button>
                            <button
                              onClick={() =>
                                handleNewPedido(cliente, sucursalesList)
                              }
                            >
                              Nuevo Pedido
                            </button>
                            <button onClick={() => goToHistorialVisitas(cliente)}>
                                  Historial de Visitas
                            </button>
                            {userType === "0" && (
                              <>
                                <button
                                  onClick={() =>
                                    handleNewVisita(cliente, sucursalesList)
                                  }
                                >
                                  Nueva Visita
                                </button>
                                <button
                                  onClick={() =>
                                    handleNewHistoria(cliente, sucursalesList)
                                  }
                                >
                                  Historia Clínica
                                </button>
                                <button onClick={() => getHistoriaPDF(cliente)}>
                                  Exportar Histórico
                                </button>
                                <Loader isLoading={loading} />
                              </>
                            )}
                            {(userType === "0" || userType === "1") && (
                              <button onClick={() => handleDelete(cliente.id)}>
                                Eliminar
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPages={totalClientes}
              itemsPerPage={10}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <h4 style={{ textAlign: "center", color: "lightgray" }}>
            No hay pacientes
          </h4>
        )}
      </div>
    </div>
  );
};

export default Clientes;
