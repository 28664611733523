import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import logo from '../../images/LogoTransparente.png';
import "../../index.css";

const PedidoVista = () => {
  const id = sessionStorage.getItem("pedidoEstatus");
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/login");
      return;
    }
    document.body.style.paddingTop = "20px";

    const fetchPedido = async () => {
      try {
        const response = await axios.get(`/pedido/${id}`);
        setPedido(response.data[0]);
        setLoading(false); 
      } catch (error) {
        console.error("Error al obtener el pedido:", error);
        Swal.fire({
          icon: "error",
          title: "Error al obtener el pedido",
          text: "Hubo un problema al cargar los detalles del pedido. Por favor, inténtalo de nuevo más tarde.",
        }).then(() => {
          
        });
        setLoading(false); 
      }
    };

    fetchPedido();
  }, [id, navigate]);

  const handleEmployeer = () =>{
    sessionStorage.setItem("idPedidoCurrent", sessionStorage.getItem("pedidoEstatus"));
    sessionStorage.removeItem("pedidoEstatus");
    window.location.href = '/login';
  }

  if (loading) {
    return <div>Cargando pedido...</div>;
  }

  if (!pedido) {
    return <div>No se encontró el pedido.</div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const formatDateWithoutTime = (dateString) => {
    const formattedDate = formatDate(dateString);
    return formattedDate.split(",")[0];
  };
  return (
    <div style={{ display: 'grid' }}>
      <img src={logo} alt="" style={{ margin: 'auto', width: '150px' }} />
      <div className="container mt-4">
        <div className="card bg-light text-black mb-3">
          <div className="card-body titlePedidoVista">
            <h2 className="mb-4" style={{ color: "black"}}>Detalles del Pedido</h2>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Cliente</h5>
            <p className="card-text">{pedido.nombreCliente}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Dirección Sucursal</h5>
            <p className="card-text">{pedido.direccionSucursal}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Empleado</h5>
            <p className="card-text">{pedido.nombreCreador}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Estatus</h5>
            <p className="card-text">{pedido.estatus}</p>
          </div>
        </div>
        {pedido.entregaRealizada !== null ? (
          <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Entrega Realizada</h5>
            <p className="card-text">{formatDate(pedido.entregaRealizada)}</p>
          </div>
        </div>
        ) : null}
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Fecha de Entrega Prevista</h5>
            <p className="card-text">{formatDateWithoutTime(pedido.fechaEntregaPrevista)}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Total</h5>
            <p className="card-text">${pedido.total}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Saldo Pendiente</h5>
            <p className="card-text">${pedido.saldoPendiente}</p>
          </div>
        </div>
      </div>
      <button className="btn btn-sm btn-secondary" onClick={handleEmployeer}>Estatus Pedido</button>
    </div>
  );
};

export default PedidoVista;
