import React, { useState } from 'react';
import logo from '../images/LogoTransparente.png';

const Default = () => {

  const containerFormParent = {
    display: 'grid',
    textAlign: 'center'
  }

  return (
     <div className="container mt-5" style={containerFormParent}>
      <img src={logo} alt='' style={{width:'15%', justifySelf: 'center'}}/>
      <h3 className='text-dark' style={{placeSelf: 'center', marginTop: '40px', width: '300px', textAlign: 'center'}}>Administrador Opticas Pelayo</h3>
      <h1>¡Hola Usuario de Laboratorio!</h1>
    </div>

  );
};

export default Default;
