
import React, { useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import Header from "../Header";
import Select from "react-select";
import "../../index.css";

const ClientesSelect = ({ index, handleClienteChange }) => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await axios.get("/clientes");
        const clientesData = response.data.data.map(cliente => ({
          value: cliente.id,
          label: `${cliente.nombreCompleto} ${cliente.email || ""}`,
        }));
        setClientes(clientesData);
      } catch (error) {
        console.error("Error al obtener clientes", error);
      }
    };
    fetchClientes();
  }, []);

  return (
    <Select
      options={clientes}
      onChange={(selectedOption) => handleClienteChange(index, selectedOption.value)}
      placeholder="Selecciona un paciente"
    />
  );
};


const Pedidos = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cliente = location.state?.cliente;
  const sucursales = location.state?.sucursales;

  if (!cliente) {
    navigate("/dashboard/clientes");
  }
  const handleClienteChange = (index, clienteId) => {
    const nuevosElementos = [...elementos];
    nuevosElementos[index].idCliente = clienteId;
    setElementos(nuevosElementos);
  };
  const [fechaEntrega, setFechaEntrega] = useState("");
  const [elementos, setElementos] = useState([
    {
      armazon: "",
      lente: "",
      otro: "",
      importeArmazon: "",
      importeLente: "",
      importeOtro: "",
      grado: "",
      tono: "",
      observaciones: "",
      idCliente: null,
    },
  ]);
  const [tipoPago, setTipoPago] = useState("total");
  const [anticipo, setAnticipo] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [formaPago, setFormaPago] = useState("");
  const [facturacion, setFacturacion] = useState("False");
  const [tipoTicketFis, setTipoTicketFis] = useState("Comprobante");

  // Función para calcular el total
  const calcularTotal = () => {
    return elementos.reduce((total, elemento) => {
      const importeArmazon = parseFloat(elemento.importeArmazon) || 0;
      const importeLente = parseFloat(elemento.importeLente) || 0;
      const importeOtro = parseFloat(elemento.importeOtro) || 0;
      total += importeArmazon + importeLente + importeOtro;
      return total;
    }, 0);
  };
  const saldo =
    tipoPago === "Anticipo"
      ? calcularTotal() -
        (isNaN(anticipo) ? (setAnticipo(null), null) : anticipo)
      : null;
  // Función para manejar el cambio en el tipo de pago
  const handleTipoPagoChange = (e) => {
    setTipoPago(e.target.value);
    setAnticipo();
  };

  // Función para manejar el cambio en la anticipo
  const handleAnticipoChange = (e) => {
    setAnticipo(parseFloat(e.target.value));
  };

  // Función para manejar el cambio en la forma de pago
  const handleFormaPagoChange = (e) => {
    setFormaPago(e.target.value);
  };

  // Función para manejar el cambio en facturación
  const handleFacturacionChange = (e) => {
    setFacturacion(e.target.value);
  };

  // Función para manejar el cambio en ticket tipo fiscal
  const handleTicketTipoChange = (e) => {
    setTipoTicketFis(e.target.value);
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    const sucursalID = localStorage.getItem("branchId");
    const sucursalActual = sucursales.find((suc) => suc.id == sucursalID);

    // Obtener todos los datos del formulario
    const formData = {
      idCliente: cliente.id,
      nombreCliente: cliente.nombreCompleto,
      idSucursal: sucursalID,
      direccionSucursal: sucursalActual.direccion || "",
      idCreador: localStorage.getItem("idUser"),
      nombreCreador: `${localStorage.getItem("name")} ${localStorage.getItem(
        "lastName"
      )} ${localStorage.getItem("secondLastName")}`,
      ultimoModificador: `${localStorage.getItem(
        "name"
      )} ${localStorage.getItem("lastName")} ${localStorage.getItem(
        "secondLastName"
      )}`,
      idUltimoModificador: localStorage.getItem("idUser"),
      estatus: "Esperando elaboración",
      fechaEntrega: fechaEntrega,
      tipoPago: tipoPago || "Total",
      observaciones: observaciones,
      formaPago: formaPago || "Efectivo",
      facturacion: facturacion,
      total: calcularTotal(),
      saldoPendiente: tipoPago === "Anticipo" ? calcularTotal() - (anticipo || 0) : 0,
      importe: tipoPago === "Anticipo" ? (anticipo || 0) : calcularTotal(),
      tipoTicketFis,
      anticipo,
      elementos,
    };

    axios
      .post("/crearPedido", formData)
      .then((response) => {
        if (response.data.success) {
          Swal.fire({
            title: "¡Pedido creado!",
            text: "El nuevo pedido ha sido creado exitosamente.",
            icon: "success",
            confirmButtonText: "Ir a tickets",
            allowOutsideClick: "false",
          }).then((result) => {
            if (result.isConfirmed) {
              const idPedido = response.data.pedidoId;
              navigate("/pedido/tickets", { state: { idPedido } });
            }
          });
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "No se pudo crear el pedido.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Error al crear pedido:", error);
        Swal.fire({
          title: "¡Error!",
          text: "Ocurrió un error al intentar crear el pedido.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  // Función para agregar un nuevo elemento
  const handleAddElemento = () => {
    setElementos([
      ...elementos,
      {
        armazon: "",
        lente: "",
        otro: "",
        importeArmazon: "",
        importeLente: "",
        importeOtro: "",
        grado: "",
        tono: "",
        observaciones: "",
      },
    ]);
  };
  // Función para eliminar un elemento
  const handleRemoveElemento = (index) => {
    if(elementos.length > 1) {
      setElementos(elementos.filter((_, i) => i !== index));
    }
  };

  // Función para manejar el cambio en un elemento específico
  const handleElementoChange = (index, e) => {
    const { name, value } = e.target;
    const updatedElementos = elementos.map((elemento, i) =>
      i === index ? { ...elemento, [name]: value } : elemento
    );
    setElementos(updatedElementos);
  };

  return (
    <div>
      <Header />
      <form
        onSubmit={handleSubmit}
        className="container formCliente"
        style={{ marginTop: "50px" }}
      >
        <h2 className="mt-4 mb-3"><b>Nuevo Pedido:</b> {cliente.nombreCompleto}</h2>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fechaEntrega">Fecha de Entrega Prevista:</label>
            <input
              type="date"
              className="form-control"
              id="fechaEntrega"
              value={fechaEntrega}
              onChange={(e) => setFechaEntrega(e.target.value)}
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="formaPago">Tipo Ticket:</label>
            <select
              className="form-control"
              id="tipoTicket"
              value={tipoTicketFis}
              onChange={handleTicketTipoChange}
              required
            >
              <option value="Comprobante">Comprobante</option>
              <option value="Nota Simple">Nota Simple</option>
            </select>
          </div>
        </div>
        {elementos.map((elemento, index) => (
          <div key={index}>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor={`armazon-${index}`}>Armazón:</label>
                <input
                  type="text"
                  className="form-control"
                  id={`armazon-${index}`}
                  name="armazon"
                  value={elemento.armazon}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Ingresa armazón"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor={`lente-${index}`}>Lente:</label>
                <input
                  type="text"
                  className="form-control"
                  id={`lente-${index}`}
                  name="lente"
                  value={elemento.lente}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Ingresa lente"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor={`otro-${index}`}>Otro:</label>
                <input
                  type="text"
                  className="form-control"
                  id={`otro-${index}`}
                  name="otro"
                  value={elemento.otro}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Ingresa si existe algun otro"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label>Importe:</label>
                <input
                  type="number"
                  className="form-control"
                  name="importeArmazon"
                  placeholder="0"
                  value={elemento.importeArmazon}
                  onChange={(e) => handleElementoChange(index, e)}
                  required
                />
              </div>
              <div className="col-md-4">
                <label>Importe:</label>
                <input
                  type="number"
                  className="form-control"
                  name="importeLente"
                  placeholder="0"
                  value={elemento.importeLente}
                  onChange={(e) => handleElementoChange(index, e)}
                />
              </div>
              <div className="col-md-4">
                <label>Importe:</label>
                <input
                  type="number"
                  className="form-control"
                  name="importeOtro"
                  placeholder="0"
                  value={elemento.importeOtro}
                  onChange={(e) => handleElementoChange(index, e)}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor={`grado-${index}`}>Grado:</label>
                <input
                  type="text"
                  className="form-control"
                  id={`grado-${index}`}
                  name="grado"
                  value={elemento.grado}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Ingresa grado"
                />
              </div>
              <div className="col-md-4">
                <label>Tono:</label>
                <input
                  type="text"
                  className="form-control"
                  name="tono"
                  value={elemento.tono}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Ingresa tono"
                />
              </div>
              <div className="col-md-4">
                <label>Observaciones:</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="observaciones"
                  value={elemento.observaciones}
                  onChange={(e) => handleElementoChange(index, e)}
                  placeholder="Observaciones adicionales"
                  style={{width: '400px', height: '80px'}}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-8">
                <ClientesSelect index={index} handleClienteChange={handleClienteChange}/>
              </div>
              <div className="col-md-4">
              </div>
            </div>
            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() => handleRemoveElemento(index)}
            >
              Eliminar Elemento
            </button>
          </div>
        ))}
        <div className="row mb-3">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddElemento}
            >
              Agregar Elemento
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="tipoPago">Tipo de Pago:</label>
            <select
              className="form-control"
              id="tipoPago"
              value={tipoPago}
              onChange={handleTipoPagoChange}
              required
            >
              <option value="total">Total</option>
              <option value="Anticipo">Anticipo</option>
            </select>
          </div>
          {tipoPago === "Anticipo" && (
            <div className="col-md-4">
              <label htmlFor="anticipo">Anticipo:</label>
              <input
                type="number"
                className="form-control"
                id="anticipo"
                placeholder="0"
                value={anticipo}
                onChange={handleAnticipoChange}
                required
              />
            </div>
          )}
          <div className="col-md-4">
            <label htmlFor="formaPago">Forma de Pago:</label>
            <select
              className="form-control"
              id="formaPago"
              value={formaPago}
              onChange={handleFormaPagoChange}
              required
            >
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia">Transferencia</option>
              <option value="Credito">Credito</option>
              <option value="Debito">Debito</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="facturacion">Facturación:</label>
            <select
              className="form-control"
              id="facturacion"
              value={facturacion}
              onChange={handleFacturacionChange}
              required
            >
              <option value="True">Sí</option>
              <option value="False">No</option>
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="observaciones">Observaciones:</label>
            <textarea
              className="form-control"
              id="observaciones"
              value={observaciones}
              onChange={(e) => setObservaciones(e.target.value)}
              rows="3"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label>Total:</label>
            <input
              type="text"
              className="form-control"
              value={`$${calcularTotal().toFixed(2)}`}
              readOnly
            />
          </div>
          {tipoPago === "Anticipo" && (
            <div className="col-md-4">
              <label>Saldo Pendiente:</label>
              <input
                type="text"
                className="form-control"
                value={`$${saldo.toFixed(2)}`}
                readOnly
              />
            </div>
          )}
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <button type="submit" className="btn btn-success">
              Guardar Pedido
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Pedidos;
